


const SOUNDS = [
  {
    "url": "https://www.tiktok.com/music/Drift-Night-7125737679639021570",
    "name": "Drift Night",
    "path": "DRIFT_NIGHT.m4a",
    "author": "Alfianie"
  },
  {
    "url": "https://www.tiktok.com/music/Suspense-horror-piano-and-music-box-6817193198436255745",
    "name": "Suspense Horror Music",
    "path": "SUSPENSE_HORROR_MUSIC.m4a",
    "author": "Audiostock"
  },
  {
    "url": "https://www.tiktok.com/music/In-Essence-Slowed-7068650753636501506",
    "name": "In Essence",
    "path": "IN_ESSENCE.m4a",
    "author": "Ka$tro"
  },
  {
    "url": "https://www.tiktok.com/music/snowfall-7043672073613936641",
    "name": "Snowfall",
    "path": "SNOWFALL.m4a",
    "author": "Øneheart"
  },
  {
    "url": "https://www.tiktok.com/music/Supra-7005687507404720130",
    "name": "Supra",
    "path": "SUPRA.m4a",
    "author": "crier"
  },
  {
    "url": "https://www.tiktok.com/music/son-original-7300270152439892768",
    "name": "GTA4",
    "path": "GTA4.m4a",
    "author": "MusicPlace🎵"
  },
  {
    "url": "https://www.tiktok.com/music/Le-Monde-From-Talk-to-Me-7257359255815292929",
    "name": "Le Monde",
    "path": "LE_MONDE.m4a",
    "author": "justrichardcarter"
  },
  {
    "url": "https://www.tiktok.com/music/original-sound-7129208880822881029",
    "name": "Transgender",
    "path": "TRANSGENDER.m4a",
    "author": "roux"
  },
  {
    "url": "https://www.tiktok.com/music/Blade-Runner-2049-6839324086548563969",
    "name": "Blade Runner 2049",
    "path": "BLADE_RUNNER_2049.m4a",
    "author": "Synthwave Goose"
  },
  {
    "url": "https://www.tiktok.com/music/Epic-Music-863502-6873501791145691137",
    "name": "Epic Music",
    "path": "EPIC_MUSIC.m4a",
    "author": "Draganov89"
  }
]
console.log(process.env.NODE_ENV)
const apiUrl = process.env.NODE_ENV == "development" ? "https://f266-96-22-174-153.ngrok-free.app/" : "https://etereel-server.onrender.com/" 
// https://etereel-server.onrender.com/
// http://localhost:8080/
const ft = {
  color: {
    primary: 'bg-indigo-600',
  },
};




const RAW_TRANSCRIPTION_TEMPLATE = [
  {
      "word": "In",
      "start": 0,
      "end": 0.1599999964237213,
      "id": "idb27acd7f3ab58",
      "type": "word"
  },
  {
      "word": "the",
      "start": 0.1599999964237213,
      "end": 0.30000001192092896,
      "id": "id7ddf69170197f",
      "type": "word"
  },
  {
      "word": "quiet",
      "start": 0.30000001192092896,
      "end": 0.5400000214576721,
      "id": "ida5c996d4d8aae",
      "type": "word"
  },
  {
      "word": "forest",
      "start": 0.5400000214576721,
      "end": 0.9399999976158142,
      "id": "id3f2e87ce70285",
      "type": "word"
  },
  {
      "word": "a",
      "start": 1.1200000047683716,
      "end": 1.2200000286102295,
      "id": "ida3b75472a0102",
      "type": "word"
  },
  {
      "word": "small",
      "start": 1.2200000286102295,
      "end": 1.4600000381469727,
      "id": "id3455dd5198811",
      "type": "word"
  },
  {
      "word": "seed",
      "start": 1.4600000381469727,
      "end": 1.7799999713897705,
      "id": "id8a4f463b5b09c",
      "type": "word"
  },
  {
      "word": "grew",
      "start": 1.7799999713897705,
      "end": 1.9800000190734863,
      "id": "id6fc739a3bbfbc",
      "type": "word"
  },
  {
      "word": "into",
      "start": 1.9800000190734863,
      "end": 2.200000047683716,
      "id": "idbf8482b4191fe",
      "type": "word"
  },
  {
      "word": "a",
      "start": 2.200000047683716,
      "end": 2.380000114440918,
      "id": "id485df7303c485",
      "type": "word"
  },
  {
      "word": "mighty",
      "start": 2.380000114440918,
      "end": 2.559999942779541,
      "id": "id026ec5eb6f972",
      "type": "word"
  },
  {
      "word": "tree",
      "start": 2.559999942779541,
      "end": 2.940000057220459,
      "id": "id61f39057810f2",
      "type": "word"
  },
  {
      "word": "reminding",
      "start": 3.4800000190734863,
      "end": 3.680000066757202,
      "id": "id15f040ecda49",
      "type": "word"
  },
  {
      "word": "us",
      "start": 3.680000066757202,
      "end": 4.019999980926514,
      "id": "id56fa2e45f389f",
      "type": "word"
  },
  {
      "word": "that",
      "start": 4.019999980926514,
      "end": 4.320000171661377,
      "id": "id33fa24e1e0d7b",
      "type": "word"
  },
  {
      "word": "great",
      "start": 4.320000171661377,
      "end": 4.599999904632568,
      "id": "idb06659aaccc3",
      "type": "word"
  },
  {
      "word": "things",
      "start": 4.599999904632568,
      "end": 4.920000076293945,
      "id": "id45e9578cead64",
      "type": "word"
  },
  {
      "word": "start",
      "start": 4.920000076293945,
      "end": 5.28000020980835,
      "id": "idba0be89f0938d",
      "type": "word"
  },
  {
      "word": "from",
      "start": 5.28000020980835,
      "end": 5.619999885559082,
      "id": "id352bb99592f2b",
      "type": "word"
  },
  {
      "word": "humble",
      "start": 5.619999885559082,
      "end": 5.860000133514404,
      "id": "id3de7a1658f6fe",
      "type": "word"
  },
  {
      "word": "beginnings",
      "start": 5.860000133514404,
      "end": 6.260000228881836,
      "id": "ide8c34c7baf886",
      "type": "word"
  }
]

const SLIDES_TEMPLATE = [
  {
      "text": "In the quiet",
      "imagePrompt": "Serene, untouched forest basking in morning light",
      "id": "idabe33b3b7f3c7",
      "type": "image",
      "start": 0,
      "end": 0.6400000214576721,
      "imageType": "ai",
      "customImageUrl": null,
      "imageUrl": "/template-images/slide1.png"
  },
  {
      "text": "forest, a small",
      "imagePrompt": "Close-up of a singular, vibrant green seed on fertile soil",
      "id": "idc9f41515a887d",
      "type": "image",
      "start": 0.5400000214576721,
      "end": 1.5600000381469727,
      "imageType": "ai",
      "customImageUrl": null,
      "imageUrl": "/template-images/slide2.png"
  },
  {
      "text": "seed grew",
      "imagePrompt": "Time-lapse view of a seed sprouting, roots spreading",
      "id": "idb67585a8c5357",
      "type": "image",
      "start": 1.4600000381469727,
      "end": 2.0800000190734864,
      "imageType": "ai",
      "customImageUrl": null,
      "imageUrl": "/template-images/slide3.png"
  },
  {
      "text": "into a mighty",
      "imagePrompt": "Vivid, towering tree standing resiliently against the sky",
      "id": "id21d4d2bcaafbd",
      "type": "image",
      "start": 1.9800000190734863,
      "end": 2.659999942779541,
      "imageType": "ai",
      "customImageUrl": null,
      "imageUrl": "/template-images/slide4.png"
  },
  {
      "text": "tree,",
      "imagePrompt": "Giant tree with a thick trunk, branches stretching wide",
      "id": "ide10492ed4b1c4",
      "type": "image",
      "start": 2.559999942779541,
      "end": 3.040000057220459,
      "imageType": "ai",
      "customImageUrl": null,
      "imageUrl": "/template-images/slide5.png"
  },
  {
      "text": "reminding us that",
      "imagePrompt": "Warm sunrise illuminating a new sapling on forest ground",
      "id": "id3e6d81fd7b43f",
      "type": "image",
      "start": 2.940000057220459,
      "end": 4.420000171661377,
      "imageType": "ai",
      "customImageUrl": null,
      "imageUrl": "/template-images/slide6.png"
  },
  {
      "text": "great things start",
      "imagePrompt": "Dynamic composition of small seeds turning into various large trees",
      "id": "id1913098cf4ea4",
      "type": "image",
      "start": 4.320000171661377,
      "end": 5.380000209808349,
      "imageType": "ai",
      "customImageUrl": null,
      "imageUrl": "/template-images/slide7.png"
  },
  {
      "text": "from humble",
      "imagePrompt": "Simplistic, unassuming seeds resting in a gardener's palm",
      "id": "id26b65c03918f9",
      "type": "image",
      "start": 5.28000020980835,
      "end": 5.960000133514404,
      "imageType": "ai",
      "customImageUrl": null,
      "imageUrl": "/template-images/slide8.png"
  },
  {
      "text": "beginnings.",
      "imagePrompt": "Panoramic view of a lush, vibrant forest at dawn",
      "id": "id2e6ae6239f58e",
      "type": "image",
      "start": 5.860000133514404,
      "end": 6.360000228881836,
      "imageType": "ai",
      "customImageUrl": null,
      "imageUrl": "/template-images/slide9.png"
  }
]

const DEFAULT_STYLE = {
  subtitlesSettings: {
    fontSize: 100,
    align: "center",
    fontWeight: "900",
    fontFamily: "Inter",
    fontFamilyImportName: "Inter",
    animation: "bounce-in",
    animationLength: 200,
    fillColor: {
      r: 255,
      g: 255,
      b: 255
    },
    outlineSize: 15,
    outlineColor: {
      r: 0,
      g: 0,
      b: 0
    }
  },
  voiceoverSettings: {
    volume: 1
  },
  musicSettings: {
    volume: 0.1,
    type: "tiktok-preselected",
    tiktokSoundSelected: SOUNDS[9],
    customSound: null
  },
  imageSettings: {
    animation: "zoom-out"
  }
}


const STYLE_PRESETS = {
  "default": {
    key: "default",
    name: "Default",
    subtitlesSettings: DEFAULT_STYLE.subtitlesSettings,
    imageSettings: DEFAULT_STYLE.imageSettings,
  },
  "beast": {
    key: "beast",
    name: "Mr Beast",
    subtitlesSettings: {
      fontFamily: "Komika Axis",
      fontFamilyImportName: "Komika Axis",
      fontSize: 85
    },
  },
  "subtitles": {
    key: "subtitles",
    name: "Subtitles",
    subtitlesSettings: {
      align: "left",
      fontFamily: "Bebas",
      fontFamilyImportName: "Bebas",
      fontSize: 85
    },
  },
  "anime": {
    key: "anime",
    name: "Anime",
    subtitlesSettings: {
      fontFamily: "Manga Temple",
      fontFamilyImportName: "Manga Temple",
      fontSize: 75,
      animation: "none"
    }
  }
}

export {SOUNDS, apiUrl, ft, STYLE_PRESETS, DEFAULT_STYLE, RAW_TRANSCRIPTION_TEMPLATE, SLIDES_TEMPLATE}