
import {
  AbsoluteFill,
  Sequence,
  spring,
  useCurrentFrame,
  useVideoConfig,
  Audio,
  interpolate,
  Easing
} from "remotion";
import { useEffect } from "react"


// ACHIEVED AT THE MIDDLE OF THE SLIDE
const TRANSITION_MAX_ZOOM = 1.2

// ACHIEVED AT THE BEGINNING AND VERY END OF THE SLIDE
const TRANSITION_MIN_ZOOM = 0.8

const TRANSITION_ZOOM_VALUES = [1, 0.6, 1.4, 1]

export default function AdRenderer({preview, imageSettings, musicSettings, voiceoverSettings, subtitlesSettings, rawTranscription, speech, slides, currentFrameRef, setCurrentFrameRef}) {  
  console.log(slides)
  let frame = useCurrentFrame()

  useEffect(() => {
    if(!preview) {
      setCurrentFrameRef(frame)
    }
    
  }, [frame])

  if(!rawTranscription || !slides) return

  let xvalues = JSON.parse(JSON.stringify(TRANSITION_ZOOM_VALUES))
  xvalues.length = 2

  
  
  let textX = "50%"
  let textAnchor = "middle"
  if(subtitlesSettings.align == "left") {
    textX = "10%"
    textAnchor = "start"
  } else if(subtitlesSettings.align == "right") {
    textX = "90%"
    textAnchor = "end"
  }

  const musicSrc = musicSettings.type == "custom"
    ? musicSettings.customSound
    : "/sounds/" + musicSettings.tiktokSoundSelected.path


  return (
    <>
      {
        preview ? null : <>
            <Audio volume={voiceoverSettings.volume} src={speech}/>
            {
              musicSettings.type == "none"
                ? null
                : (musicSettings.type == "custom"
                  ? (musicSettings.customSound ? <Audio volume={musicSettings.volume} src={musicSrc}/> : null)
                  : <Audio volume={musicSettings.volume} src={musicSrc}/>)
            }
          </>
      }
      
      
      <AbsoluteFill style={{backgroundColor: "black"}}>
        {
          [...slides].reverse().map(slide => {
            let scaleValue = 1
            let opacityValue = 1
            let startFrame = toFrames(slide.start)
            let endFrame = toFrames(slide.end)
            /*
            let middle1Frame = toFrames(slide.start + (slide.end - slide.start) / 8 * 1)
            let middleFrame = toFrames(slide.start + (slide.end - slide.start) / 8 * 7)
            
            

            

            if(frame < middle1Frame) {
              scaleValue = interpolate(frame, [startFrame, middle1Frame], [TRANSITION_ZOOM_VALUES[0], TRANSITION_ZOOM_VALUES[1]], {
                easing: Easing.bezier(0,.77,1,1)
              })
            } else if(frame < middleFrame) {
              scaleValue = interpolate(frame, [startFrame, middle1Frame, middleFrame, endFrame], TRANSITION_ZOOM_VALUES, {
                easing: Easing.bezier(.65,-0.01,.6,.81)
              })
            } else {
              scaleValue = interpolate(frame, [middleFrame, endFrame], [TRANSITION_ZOOM_VALUES[2], TRANSITION_ZOOM_VALUES[3]], {
                easing: Easing.bezier(0,0,1,.77)
              })
              TRANSITION_ZOOM_VALUES[2] - (frame - middleFrame) * 0.005
            } */
            
            if(imageSettings.animation == "zoom-out") {
              opacityValue = interpolate(frame, [startFrame, Math.max(startFrame + 1, endFrame - 10), endFrame], [1, 1, 0])
              scaleValue = interpolate(frame, [startFrame, endFrame - 3], [1.4, 0.9], {
                easing: Easing.bezier(1.000, 0.590, 1.000, 0.545)
              })
            }
            let renderSpecial = false
            if(scaleValue < 1) {
              renderSpecial = true
            }
            if(slide.imagePrompt != "PRODUCT_PICTURE") {
              let imageUrl = slide.imageType == "ai" ? slide.imageUrl : (slide.customImageUrl ? slide.customImageUrl : null)
              return <Sequence from={toFrames(slide.start)-10} durationInFrames={toFrames(slide.end - slide.start)+20} layout="none">
                {
                  imageUrl
                    ? <div style={{transform: `scale(${scaleValue})`, position: "absolute", top: 0, left: 0, width: "1080px", height: "1920px", backgroundImage: "url(" + imageUrl + ")", backgroundSize: "auto 100%", backgroundPosition: "center", opacity: opacityValue}}/>
                    : null
                }
                {/* {
                  renderSpecial
                    ? <>
                      <div style={{filter: "blur(4px)", transform: `scale(${scaleValue}) scaleY(-1) translateY(-99.9%)`, position: "absolute", top: 0, left: 0, width: "1080px", height: "1920px", backgroundImage: "url(" + slide.imageUrl + ")", backgroundSize: "auto 100%", backgroundPosition: "center"}}/>
                      <div style={{filter: "blur(4px)", transform: `scale(${scaleValue}) scaleY(-1) translateY(99.9%)`, position: "absolute", top: 0, left: 0, width: "1080px", height: "1920px", backgroundImage: "url(" + slide.imageUrl + ")", backgroundSize: "auto 100%", backgroundPosition: "center"}}/>
                      <div style={{filter: "blur(4px)", transform: `scale(${scaleValue}) scaleX(-1) translateX(-99.9%)`, position: "absolute", top: 0, left: 0, width: "1080px", height: "1920px", backgroundImage: "url(" + slide.imageUrl + ")", backgroundSize: "auto 100%", backgroundPosition: "center"}}/>
                      <div style={{filter: "blur(4px)", transform: `scale(${scaleValue}) scaleX(-1) translateX(99.9%)`, position: "absolute", top: 0, left: 0, width: "1080px", height: "1920px", backgroundImage: "url(" + slide.imageUrl + ")", backgroundSize: "auto 100%", backgroundPosition: "center"}}/>
                      <div style={{filter: "blur(4px)", transform: `scale(${scaleValue}) scaleY(-1) translateY(-99.9%) scaleX(-1) translateX(-99.9%)`, position: "absolute", top: 0, left: 0, width: "1080px", height: "1920px", backgroundImage: "url(" + slide.imageUrl + ")", backgroundSize: "auto 100%", backgroundPosition: "center"}}/>
                      <div style={{filter: "blur(4px)", transform: `scale(${scaleValue}) scaleY(-1) translateY(99.9%) scaleX(-1) translateX(99.9%)`, position: "absolute", top: 0, left: 0, width: "1080px", height: "1920px", backgroundImage: "url(" + slide.imageUrl + ")", backgroundSize: "auto 100%", backgroundPosition: "center"}}/>
                      <div style={{filter: "blur(4px)", transform: `scale(${scaleValue}) scaleX(-1) translateX(99.9%) scaleY(-1) translateY(-99.9%)`, position: "absolute", top: 0, left: 0, width: "1080px", height: "1920px", backgroundImage: "url(" + slide.imageUrl + ")", backgroundSize: "auto 100%", backgroundPosition: "center"}}/>
                      <div style={{filter: "blur(4px)", transform: `scale(${scaleValue}) scaleX(-1) translateX(-99.9%) scaleY(-1) translateY(99.9%)`, position: "absolute", top: 0, left: 0, width: "1080px", height: "1920px", backgroundImage: "url(" + slide.imageUrl + ")", backgroundSize: "auto 100%", backgroundPosition: "center"}}/>

                    </>
                    : null
                } */}
              </Sequence>
            } else {
              return <AbsoluteFill style={{backgroundColor: "black"}}></AbsoluteFill>
            }
            
          })
        }
        
        <svg width="1080" height="1920" style={{top: 0, left: 0, position: "absolute"}}>
{/*         <text style={{fill: "white", width: "100%", fontWeight: 900, fontFamily: "inter"}} x="50%" y="900" fontSize="100" textAnchor="middle" dominantBaseline="middle" stroke="black" strokeWidth="15" paintOrder="stroke">{"sDFSDFSDSD"}</text>
 */}          {
            rawTranscription.map(word => {
              let opacityValue = 1
              let scaleValue = 1
              let startFrame = toFrames(word.start)
              let start2Frame = toFrames(word.start + subtitlesSettings.animationLength / 1000)
              let endFrame = toFrames(word.end)
              start2Frame = Math.min(start2Frame, Math.max(endFrame - 0.1, startFrame)) + 0.05
              if(subtitlesSettings.animation == "fade-in") {
                opacityValue = interpolate(frame, [startFrame, start2Frame, endFrame + 0.1], [0, 1, 1])
              } else if(subtitlesSettings.animation == "bounce-in") {

                scaleValue = spring({
                  fps: 60,
                  frame,
                  delay: startFrame,
                  durationInFrames: toFrames(subtitlesSettings.animationLength/1000)
                })
              }
              return <Sequence from={startFrame} durationInFrames={toFrames(word.end - word.start)} layout="none">
                <text style={{transform: `translate(${textX}, 1300px) scale(${scaleValue})`, opacity: opacityValue, fill: `rgba(${(word.fillColor && word.fillColor.r) || subtitlesSettings.fillColor.r}, ${(word.fillColor && word.fillColor.g) || subtitlesSettings.fillColor.g}, ${(word.fillColor && word.fillColor.b) || subtitlesSettings.fillColor.b}, 1)`, width: "100%", fontWeight: word.fontWeight || subtitlesSettings.fontWeight, fontFamily: word.fontFamily || subtitlesSettings.fontFamily}} fontSize={word.fontSize || subtitlesSettings.fontSize} textAnchor={textAnchor} dominantBaseline="middle" stroke={`rgba(${(word.outlineColor && word.outlineColor.r) || subtitlesSettings.outlineColor.r}, ${(word.outlineColor && word.outlineColor.g) || subtitlesSettings.outlineColor.g}, ${(word.outlineColor && word.outlineColor.b) || subtitlesSettings.outlineColor.b}, 1)`} strokeWidth={word.outlineSize || subtitlesSettings.outlineSize} paintOrder="stroke">{word.word}</text>
              </Sequence>
              
            })
          }
        </svg>
        
        
      </AbsoluteFill>
    </>
    
  )
}

function toFrames(x, fps=60) {
  return Math.max(x*fps, 1)
}

