"use client"

import { useState, useEffect, useRef } from "react"
import { useThemeMode } from 'flowbite-react';

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import VideoEditorHandler from "./Components/VideoEditorHandler.js"
import VideoGeneratorInput from "./Components/VideoGeneratorInput.js"
import VideoGeneratorProgress from "./Components/VideoGeneratorProgress.js"
import VideoGeneratorError from "./Components/VideoGeneratorError.js"
import {apiUrl} from "./constants.js"
import {GoogleLoginPreset} from "./util.js"




export default function Dashboard() {
  const [user, setUser] = useState(null)
  const {mode, setMode} = useThemeMode();
    

  const [stage, setStage] = useState("home")
  const [script, setScript] = useState(null)
  const [update, setUpdate] = useState(1)


  const videoEditorHandlerRef = useRef()
  const videoGeneratorInputRef = useRef()
  const videoGeneratorErrorRef = useRef()

  useEffect(() => {
    console.log("this gets ran??")
    setMode("dark")

    fetch(apiUrl + "api/user-data", {
      method: "GET",
      withCredentials: true,
      credentials: "include",
    }).then(async rawData => {
      const data = await rawData.json()
      if(data.status == "ok") {
        setUser(data.user)
      } else {
        setUser(null)
      }
    })
  }, [])




  
  return (
    <GoogleOAuthProvider clientId="643420478972-h86m5r4f64859jbs2fmoerfhk8vg0p2o.apps.googleusercontent.com">
      <>
        <div className="bg-gray-900 w-full h-full flex flex-col items-center" style={{display: stage == "editor" ? "none" : "flex"}}>
          <div className="w-full max-w-6xl flex flex-col mt-6">
            <div className="flex flex-row justify-between">
              <a href="/"><img src="logo.svg" className="h-12 w-fit" /></a>
              <div className="flex flex-row items-center">
                <a className={"font-medium text-white/60 flex h-full items-center justify-center text-lg ml-10" + (user ? "" : " mr-10")} href="/pricing">Pricing</a>
{/*                 <a className={"font-medium text-white/60 flex h-full items-center justify-center text-lg ml-10" + (!user ? " mr-8" : "")} href="/showcase">Show case</a>
 */}                {
                  user
                    ? <a className="font-medium text-white/60 flex h-full items-center justify-center text-lg ml-10" href="/dashboard">Dashboard</a>
                    : <GoogleLoginPreset setUser={setUser}/>
                }
                
              </div>
            </div>
            <h1 className="text-white text-5xl text-center font-bold mt-20">Dashboard</h1>
            {
              user
                ? <p className="text-white/70 text-xl text-center mt-4 ">You're currently at {user.balance} tokens</p>
                : null
            }
            
            <VideoGeneratorInput advanced={true} {...{update, setUpdate, videoEditorHandlerRef, videoGeneratorInputRef, videoGeneratorInputRef, videoGeneratorErrorRef, user, setStage, setScript}} ref={videoGeneratorInputRef}/>
          </div>

          
        
          
          <VideoGeneratorError setUser={setUser} ref={videoGeneratorErrorRef}/>
          {
            videoGeneratorInputRef.current && <VideoGeneratorProgress videoGeneratorInputRef={videoGeneratorInputRef} generatingVideoStage={videoGeneratorInputRef.current.generatingVideoStage} generatingVideo={videoGeneratorInputRef.current.generatingVideo}/>
          }
          
        </div>
        <VideoEditorHandler update={update} setUpdate={setUpdate} videoGeneratorInputRef={videoGeneratorInputRef} ref={videoEditorHandlerRef} script={script} show={stage == "editor"}/>
      </>   



  </GoogleOAuthProvider>
    
  );
}