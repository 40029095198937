"use client"

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useState, useEffect } from "react"
import { Button, Modal, Progress, useThemeMode, Textarea } from 'flowbite-react';
import {apiUrl} from "./constants.js"
import {loadStripe} from "@stripe/stripe-js"

const stripe = loadStripe("pk_live_51P81JWIcPeY1JVBtzlx2WbnHoivem6Pve10o7OUHE7FdtPa5l4Wh8jb1Csp3KjgbrmPLtwDEbTUZ4QlQ6HW9ZIte00KItHhTFr") // loadStripe("pk_test_51P81JWIcPeY1JVBtjfyEcqvQwLwXjvnL2y2ylRUVO1QptPjS9iazFbzgJ1BKhGKhj5MXe3EgWetQV9RQQdNgUY5s00mxvUGAhd")


export default function PricingPage() {
  const [user, setUser] = useState(null)
  const [purchaseErrorOpened, setPurchaseErrorOpened] = useState(null)

  const {mode, setMode} = useThemeMode();

  useEffect(() => {
    fetch(apiUrl + "api/user-data", {
      method: "GET",
      withCredentials: true,
credentials: "include",
    }).then(async rawData => {
      const data = await rawData.json()
      if(data.status == "ok") {
        setUser(data.user)
      } else {
        setUser(null)
      }
    })
  }, [])

  return <GoogleOAuthProvider clientId="643420478972-h86m5r4f64859jbs2fmoerfhk8vg0p2o.apps.googleusercontent.com">
    <div className="bg-gray-900 w-full h-full flex flex-col items-center" style={{overflow: "auto"}}>
      <div className="w-full max-w-6xl flex flex-col mt-6">
        <div className="flex flex-row justify-between">
          <a href="/"><img src="logo.svg" className="h-12 w-fit" /></a>
          <div className="flex flex-row items-center">
            <a className="font-medium text-white/60 flex h-full items-center justify-center text-lg ml-10" href="/pricing">Pricing</a>
{/*             <a className={"font-medium text-white/60 flex h-full items-center justify-center text-lg ml-10" + (!user ? " mr-8" : "")} href="/showcase">Show case</a>
 */}            {
              user
                ? <a className="font-medium text-white/60 flex h-full items-center justify-center text-lg ml-10" href="/dashboard">Dashboard</a>
                : <GoogleLogin onSuccess={(credentialResponse) => {
                  const credential = credentialResponse.credential
                    fetch(apiUrl + "api/auth", {
                      method: "POST",
                      body: JSON.stringify({
                        credential
                      }),
                      headers: {
                        'Access-Control-Allow-Origin': '*', 
                        "Content-Type": "application/json"
                      },
                      withCredentials: true,
                      credentials: "include"
                    }).then(async rawData => {
                      let data = await rawData.json()
                      if(data.status == "ok") {
                        
                        setUser(data.user)
                      } else {
                        alert("something wrong with auth")
                      }
                    }).catch(error => {
                      console.log(error)
                      console.log(setUser)
                      //alert("error when fetching")
                    })
                  }} onError={() => {
                    console.log("SIGMA ERROR DETECTED")
                  }}/>
            }
            
          </div>
        </div>
        <h3 className="text-white text-5xl text-center font-bold mt-20 mb-4">LIMITED TIME DISCOUNT: <span style={{color: "#22FA92"}}>50% OFF</span> EVERYTHING</h3>
        <p className="text-white/70 text-xl text-center mt-4 ">
          Start for free, pay ONLY for how much you use. (PAY AS YOU GO)
          <br/>20 sec. reel = ~500 credits
          {
            user
              ? <><br/><br/>You're currently at {user.balance} tokens</>
              : null
          }
        </p>
        <div className="flex flex-row mt-4">
          <PricingPlan setPurchaseErrorOpened={setPurchaseErrorOpened} user={user} special="BEST VALUE" credits="60k" videos="120" label="For serious content creators" originalPrice="59.99" price="29.99" />
          <PricingPlan setPurchaseErrorOpened={setPurchaseErrorOpened} user={user} special="MOST POPULAR" credits="30k" videos="60" label="For anyone starting content creation" originalPrice="39.99" price="19.99" />
          <PricingPlan setPurchaseErrorOpened={setPurchaseErrorOpened} user={user} noMargin credits="10k" videos="20" label="For casual videos" originalPrice="19.99" price="9.99" />
        </div>
        <h3 className="text-white text-5xl text-center font-bold mt-20">What's included?</h3>
        <div className="flex flex-row mt-6 items-center">
          <div className="grow flex flex-col">
            <p className="text-white font-bold" style={{fontSize: "28px", opacity: 0.9}}>UNLIMITED CHANGES</p>
            <p className="text-white/70 text-xl mt-4" style={{lineHeight: "160%"}}>
              Even after rendering your video, you can still make any changes you want. From changing the font, to the AI voice to even the clips themselves, everything stays very customizable. Render your video as many times as you need.
            </p>
            <img src="edits.png" style={{height: "60px", objectFit: "contain", width: "fit-content", marginTop: "20px"}}/>
          </div>
          <img src="https://storage.googleapis.com/etereel/unlimited-changes.gif" style={{width: "300px", marginLeft: "8px"}}/>
        </div>
        <div className="flex flex-row mt-6 items-center">
          <div className="grow flex flex-col">
            <p className="text-white font-bold" style={{fontSize: "28px", opacity: 0.9}}>SHORTEST GENERATION TIMES</p>
            <p className="text-white/70 text-xl mt-4" style={{lineHeight: "160%"}}>
              We provide the shortest generation times on the market. A normal 20 second video will generate in under a minute which is shorter than the 8-10 minutes provided by OSSA.AI. Also definitely shorter than what it takes humans :)
            </p>
          </div>
          <img src="/generation-times.png" style={{width: "300px", marginLeft: "8px"}}/>
        </div>
        
      </div>
    </div>

    <Modal show={purchaseErrorOpened} size="md" popup onClose={() => {setPurchaseErrorOpened(false)}}>
      <Modal.Header>{/* {{"not-logged-in": "Login to continue", "out-of-balance": "Out of balance"}[generateErrorOpened]} */}</Modal.Header>
      {{
        "not-logged-in": <Modal.Body>
          <p className="text-white text-center mb-4">To use Etereel, sign in with google. It's one click, no credit card required.</p>
          <div className="w-full flex justify-center">
            <GoogleLogin shape="pill" onSuccess={(credentialResponse) => {
              
              const credential = credentialResponse.credential
                fetch(apiUrl + "api/auth", {
                  method: "POST",
                  body: JSON.stringify({
                    credential
                  }),
                  headers: {
                    'Access-Control-Allow-Origin': '*', 
                    "Content-Type": "application/json"
                  },
                  credentials: "include",
                  withCredentials: true,
                  credentials: "include"
                }).then(async rawData => {
                  let data = await rawData.json()
                  if(data.status == "ok") {
                    setUser(data.user)
                    setPurchaseErrorOpened(false)
                  } else {
                    alert("something wrong with auth")
                  }
                })
              }} onError={() => {
                console.log("SIGMA ERROR DETECTED")
              }}/>
          </div>
        </Modal.Body>,
      }[purchaseErrorOpened]}
    </Modal>
  </GoogleOAuthProvider>
}

function PricingPlan({user, setPurchaseErrorOpened, special, credits, videos, label, originalPrice, price, noMargin}) {
  const makePayment = async () => {
    const rawResponse = await fetch(apiUrl + "api/purchase-credits", {
      method: "POST",
      body: JSON.stringify({
        creditsCount: credits
      }),
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include",
      followAllRedirects: true
    })

    const response = await rawResponse.json()
    if(response.status == "ok") {
      window.location.href = response.session.url
    } else {
      alert("Something went wrong... Try again later.")
    }
    
  }



  return <div style={{flex: "1 1 0px"}} className={"pl-5 pb-5 pr-5 border-2 border-gray-700 rounded-xl mt-10" + (noMargin ? "" : " mr-6")}>
    <div className="w-full flex flex-row justify-center" style={!special ? {opacity: 0} : {}}>
      <div style={{borderRadius: "100px", transform: "translateY(-50%)"}} className="bg-gray-700 h-8 min-w-1/2 pl-4 pr-4 text-white flex items-center justify-center">
        {special}
      </div>
    </div>
    <p className="text-white" style={{fontSize: "22px"}}>{credits} credits <span style={{fontSize: "16px", opacity: 0.9}}>({videos} videos)</span></p>
    <p className="text-white" style={{fontSize: "16px", opacity: 0.9}}>{label}</p>
    <p className="text-white font-bold mt-3" style={{fontSize: 22}}><span style={{textDecorationLine: "line-through"}}>${originalPrice} USD</span> <span style={{color: "#22FA92", textDecorationLine: "none"}}>${price} USD</span></p>
    <Button gradientMonochrome={special ? "purple" : null} className="mt-4 w-full" style={!special ? {backgroundColor: "transparent", borderWidth: 2, borderColor: "#6E4CDF"} : {}} pill onClick={() => {
      if(!user) {
        setPurchaseErrorOpened("not-logged-in")
      } else {
        makePayment()
      }
    }}>Purchase</Button>
  </div>
}