import AdRenderer from "./AdRenderer.js"
import { Player, useCurrentFrame } from "@remotion/player";
import { BsChatRightTextFill, BsFillImageFill, BsMicFill, BsMusicNoteBeamed } from "react-icons/bs";
import { useState, useEffect, useRef, useCallback } from "react"
import WavesurferPlayer from '@wavesurfer/react'
import { WaveSurfer } from 'wavesurfer-react';
import { Dropdown, Button } from 'flowbite-react';
import { RgbColorPicker } from "react-colorful";
import { Spinner, RangeSlider, Label, Radio, FileInput, TextInput, Textarea  } from 'flowbite-react';
import { SOUNDS, apiUrl } from "./constants.js"
import { IoClose } from "react-icons/io5";
import { FaClapperboard } from "react-icons/fa6";
import { BsCloudDownloadFill } from "react-icons/bs";


const fps = 60



const SECONDS_DISTANCE = 50
const SECOND_ESTIMATED_WIDTH = 100
const VOICES = [{text: "OpenAI Alloy", id: "alloy"}, {text: "OpenAI Echo", id: "echo"}, {text: "OpenAI Fable", id: "fable"}, {text: "OpenAI Onyx", id: "onyx"}, {text: "OpenAI Nova", id: "nova"}, {text: "OpenAI Shimmer", id: "shimmer"}]
const controller = new AbortController()
const signal = controller.signal

function SliderSection({label, min, max, value, setValue}) {
  return <div className="flex flex-row justify-between min-h-12 items-center">
    <p className="text-slate-400 h-fit text-lg">
      {label}
    </p>
    <div className="flex flex-row h-fit">
      <RangeSlider min={min*100} max={max * 100} value={value * 100} onInput={(v) => {
        setValue(v.target.value / 100)
      }}/>
      {/* <p className="text-slate-200 text-lg" contentEditable suppressContentEditableWarning={true} onKeyDown={(e) => {
        if(e.nativeEvent.key == "Enter") {
          e.currentTarget.blur()
          e.preventDefault()
          submit(e)
        }
      }} onBlur={(e) => {
        submit(e)
      }}>{value}</p> */}
    </div>
  </div>
}
function ColorSelectSection({label, value, setValue}) {
  const [open, setOpen] = useState(false)
  const popover = useRef();

  return <div className="flex flex-row justify-between min-h-12 items-center relative">
    <p className="text-slate-400 h-fit text-lg">
      {label}
    </p>
    <div className="flex flex-row h-fit" onClick={() => {
      setOpen(!open)
    }}>
      <div style={{backgroundColor: `rgba(${value.r}, ${value.g}, ${value.b}, 1)`, width: 20, height: 20, borderRadius: 5}}/>
        
      <div className="flex flex-col ml-2">
        <img src="properties-down.svg" className="h-2 cursor-pointer mt-2"/>
      </div>
    </div>
    {open && (
      <div className="popover" ref={popover} style={{position: "absolute", right: 0, top: 60}}>
        <RgbColorPicker color={value} onChange={(v) => {
          setValue(v)
        }} />
      </div>
    )}
  </div>
}

function NumberSelectSection({label, value, setValue}) {
  const submit = (e) => {
    let newValue = parseInt(e.currentTarget.textContent.trim())
    if(!isNaN(newValue)) {
      e.currentTarget.textContent = newValue
      setValue(newValue)
    } else {
      e.currentTarget.textContent = value
    }


    
  }

  return <div className="flex flex-row justify-between min-h-12 items-center">
    <p className="text-slate-400 h-fit text-lg">
      {label}
    </p>
    <div className="flex flex-row h-fit">
      <p className="text-slate-200 text-lg" contentEditable suppressContentEditableWarning={true} onKeyDown={(e) => {
        if(e.nativeEvent.key == "Enter") {
          e.currentTarget.blur()
          e.preventDefault()
          submit(e)
        }
      }} onBlur={(e) => {
        submit(e)
      }}>{value}</p>
      <div className="flex flex-col ml-2">
        <img src="properties-up.svg" className="h-2 mb-1 cursor-pointer" onClick={() => {
          setValue(value + 1)
        }}/>
        <img src="properties-down.svg" className="h-2 mt-1 cursor-pointer" onClick={() => {
          setValue(value - 1)
        }}/>
      </div>
    </div>
  </div>
}
function DropdownSelectSection({label, options, value, setValue}) {
  //console.log(options.map(option => {}))


  return <div className="flex flex-row justify-between min-h-12 items-center">
    <p className="text-slate-400 h-fit text-lg">
      {label}
    </p>
    <div className="flex flex-row h-fit">
      <Dropdown className="max-h-96 overflow-auto" label={"DEED"} renderTrigger={() => 
        <div className="flex flex-row cursor-pointer items-center">

          <p className="text-slate-200 text-lg">{options.find(o => o.id == value).text}</p>
          <img src="properties-down.svg" className="h-2 mt-1 cursor-pointer ml-2"/>
        </div>
      }>
        {options.map(option => {
          return <Dropdown.Item className="flex flex-col justify-start items-start" onClick={() => {
            setValue(option)
          }}>
            <span className={option.author ? "text-white font-semibold" : ""}>{option.text}</span>
            {
              option.author
                ? <span className="">{option.author}</span>
                : null
            }
          </Dropdown.Item>
        })}
      </Dropdown>
      {/* <p className="text-slate-200 text-lg" contentEditable suppressContentEditableWarning={true} onKeyDown={(e) => {
        if(e.nativeEvent.key == "Enter") {
          e.currentTarget.blur()
          e.preventDefault()
          submit(e)
        }
      }} onBlur={(e) => {
        submit(e)
      }}>{value}</p> */}
    </div>
  </div>
}


export default function Editor({renderVideo, imageSettings, setImageSettings, musicSettings, setMusicSettings, voiceoverSettings, setVoiceoverSettings, subtitlesSettings, setSubtitlesSettings, setSlides, videoDuration, rawTranscription, speech, slides, setSpeech, setRawTranscription, script, setVideoDuration }) {
  const [timelineZoom, setTimelineZoom] = useState(1)
  const [timelineOffsetSeconds, setTimelineOffsetSeconds] = useState(1)
  const currentFrameRef = useRef(0);
  const videoCursorRef = useRef();
  const playerRef = useRef()
  
  
  
  
  const [openedPropertiesWindow, setOpenedPropertiesWindow] = useState("subtitles")
  const [selectedElement, setSelectedElement] = useState(null)

  // for dragging the timeline
  const [startingTimelineOffsetSeconds, setStartingTimelineOffsetSeconds] = useState(null)
  const [startingMousePosition, setStartingMousePosition] = useState(null)
  const [newVoice, setNewVoice] = useState(null)
  const [currentVoice, setCurrentVoice] = useState("alloy")
  const [changingVoice, setChangingVoice] = useState(false)
  const [changingImage, setChangingImage] = useState(false)
  const [exportOpened, setExportOpened] = useState(false)
  const [currentlyExporting, setCurrentlyExporting] = useState(false)

  function getTimelinePosition(seconds) {
    return ((seconds - timelineOffsetSeconds) * SECONDS_DISTANCE) * timelineZoom - 20
  }
  function getSecondsFromPosition(position) {
    // Undo the scaling and offset applied in getTimelinePosition
    const adjustedPosition = (position + 20) / (timelineZoom * SECONDS_DISTANCE);
  
    // Add back the offset to get the actual seconds
    return adjustedPosition + timelineOffsetSeconds;
  }

  const setCurrentFrameRef = (frame) => {
    currentFrameRef.current = frame
    videoCursorRef.current.style.left = getTimelinePosition(frame / fps) + "px"
  }

  const setStatePropValue = (state, setState, key, newValue) => {
    setState({
      ...state,
      [key]: newValue
    })
    state[key] = newValue
  }

  useEffect(() => {
    window.addEventListener("wheel", event => {
      if(event.deltaY > 0) {
        setTimelineZoom(Math.min(10, Math.max(0.08, timelineZoom - timelineZoom/8)))
      } else {
        setTimelineZoom(Math.min(10, Math.max(0.08, timelineZoom + timelineZoom/8)))
      }
    })
  }, [timelineZoom])

  const updateSelectedTranscription = (object) => {
    let newSelectedElement = selectedElement.map(x => {
      return {
        ...x,
        ...object
      }
    })
    setSelectedElement(newSelectedElement)
    setRawTranscription(rawTranscription.map(x => {
      let found = newSelectedElement.find(y => {return y.id == x.id})
      if(found) {
        return found
      } else {
        return x
      }
      
    }))
  }
  const updateSelectedImage = (object) => {
    console.log(slides)
    let newSelectedElement = selectedElement.map(x => {
      return {
        ...x,
        ...object
      }
    })
    setSelectedElement(newSelectedElement)
    setSlides(slides.map(x => {
      let found = newSelectedElement.find(y => {return y.id == x.id})
      if(found) {
        return found
      } else {
        return x
      }
      
    }))
  }

  return <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column"}} onPointerUp={() => {
    //setStartingMousePosition(null)
    setStartingTimelineOffsetSeconds(null)
  }} onPointerMove={(event) => {
    if(startingTimelineOffsetSeconds) {
      let deltaX = (event.clientX - startingMousePosition) / timelineZoom
      // positive if we go back
      let deltaSeconds = deltaX / (SECONDS_DISTANCE)
      setTimelineOffsetSeconds(Math.max(startingTimelineOffsetSeconds - deltaSeconds, -0.5))
    }
    
  }}>
    <div className="w-full grow bg-gray-900 border-gray-600 border-b-2 flex flex-row overflow-hidden">
      <div
        className="w-96 border-r-2 border-gray-400 h-full pt-8 pb-8 pl-8 pr-8 flex flex-col" style={{maxHeight: "100%", overflow: "auto"}}
      >
        {
          openedPropertiesWindow == "subtitles"
            ? <>
              <div className="flex flex-row justify-between items-center mb-4">
                <div className="flex flex-row">
                  <BsChatRightTextFill color="white" size="30"/>
                  <h2 className="text-slate-100 font-bold ml-4 text-xl">{ selectedElement ? "Subtitle (selected)" : "Subtitles" }</h2>
                </div>
                {
                  selectedElement 
                    ? <IoClose style={{cursor: "pointer"}} size="20" color="white" onClick={() => {
                      setSelectedElement(null)
                    }}/>
                    : null
                }
                
                
              </div>

              {
                selectedElement
                  ? <>
                    <p className="text-slate-100 font-medium text-xl mt-6">Text</p>
                    <TextInput placeholder="Subtitle text" value={selectedElement[0].word} onChange={(e) => {
                      console.log("WERE UPDATING THE TEXT TO ", e.target.value)
                      updateSelectedTranscription({word: e.target.value})
                    }}/>
                  </>
                  : null
              }
              
              <p className="text-slate-100 font-medium text-xl mt-6">Display</p>
              <NumberSelectSection label="Font size (px)" min="2" max="200" value={(selectedElement && selectedElement[0].fontSize) || subtitlesSettings.fontSize} setValue={(v) => {
                if(selectedElement) {
                  updateSelectedTranscription({fontSize: v})
                } else {
                  setStatePropValue(subtitlesSettings, setSubtitlesSettings, "fontSize", v)
                }
              }}/>
              {
                selectedElement
                  ? null
                  : <DropdownSelectSection label="Align" options={[{text: "Left", id: "left"}, {text: "Center", id: "center"}, {text: "Right", id: "right"}]} value={subtitlesSettings.align} setValue={(v) => {
                    setStatePropValue(subtitlesSettings, setSubtitlesSettings, "align", v.id)
                  }}/>
              }
              
              <DropdownSelectSection label="Font" options={["Inter", "URW Geometric", "Komika Axis", "Montserrat", "Arvo", "Gilroy", "Bebas", "Bazinga", "Lemon Milk", "Manga Temple"].map(x => {return {text: x, id: x}})} value={(selectedElement && selectedElement[0].fontFamilyImportName) || subtitlesSettings.fontFamilyImportName} setValue={async (v) => {
                if(selectedElement) {
                  updateSelectedTranscription({fontFamily: v.text, fontFamilyImportName: v.id})
                } else {
                  setSubtitlesSettings({
                    ...subtitlesSettings,
                    fontFamily: v.text,
                    fontFamilyImportName: v.id
                  })
                }
                
                /* const loaded = await availableFonts.find(font => font.importName == v.id).load()
                loaded.loadFont() */
              }}/>
              <DropdownSelectSection label="Font weight" options={[{text: "100", id: "100"}, {text: "200", id: "200"}, {text: "300", id: "300"}, {text: "400", id: "400"}, {text: "500", id: "500"}, {text: "600", id: "600"}, {text: "700", id: "700"}, {text: "800", id: "800"}, {text: "900", id: "900"}]} value={(selectedElement && selectedElement[0].fontWeight) || subtitlesSettings.fontWeight} setValue={(v) => {
                if(selectedElement) {
                  updateSelectedTranscription({fontWeight: v.id})
                } else {
                  setStatePropValue(subtitlesSettings, setSubtitlesSettings, "fontWeight", v.id)
                }
              }}/>
              
              <ColorSelectSection label="Fill color" value={(selectedElement && selectedElement[0].fillColor) || subtitlesSettings.fillColor} setValue={(v) => {
                if(selectedElement) {
                  updateSelectedTranscription({fillColor: v})
                } else {
                  setStatePropValue(subtitlesSettings, setSubtitlesSettings, "fillColor", v)
                }
              }}/>
      
              <p className="text-slate-100 font-medium text-xl mt-6">Outline</p>
              <NumberSelectSection label="Outline size (px)" min="2" max="200" value={(selectedElement && selectedElement[0].outlineSize) || subtitlesSettings.outlineSize} setValue={(v) => {
                if(selectedElement) {
                  updateSelectedTranscription({outlineSize: v})
                } else {
                  setStatePropValue(subtitlesSettings, setSubtitlesSettings, "outlineSize", v)
                }
              }}/>
              <ColorSelectSection label="Outline color" value={(selectedElement && selectedElement[0].outlineColor) || subtitlesSettings.outlineColor} setValue={(v) => {
                if(selectedElement) {
                  updateSelectedTranscription({outlineColor: v})
                } else {
                  setStatePropValue(subtitlesSettings, setSubtitlesSettings, "outlineColor", v)
                }
              }}/>

              {
                selectedElement
                  ? null
                  : <>
                    <p className="text-slate-100 font-medium text-xl mt-6">Animate</p>
                    <DropdownSelectSection label="Animation" options={[{text: "None", id: "none"}, {text: "Fade in", id: "fade-in"}, {text: "Bounce in", id: "bounce-in"}]} value={subtitlesSettings.animation} setValue={(v) => {
                      setStatePropValue(subtitlesSettings, setSubtitlesSettings, "animation", v.id)
                    }}/>
                    <NumberSelectSection label="Animation length (ms)" min="2" max="200" value={subtitlesSettings.animationLength} setValue={(v) => {
                      setStatePropValue(subtitlesSettings, setSubtitlesSettings, "animationLength", v)
                    }}/>
                  </>
              }
              
              
            </>
            : openedPropertiesWindow == "voiceover"
            ? <>
              <div className="flex flex-row">
                <BsMicFill color="white" size="30"/>
                <h2 className="text-slate-100 font-bold ml-4 text-xl mb-4">Voice-over</h2>
              </div>

              {
                changingVoice
                  ? <>
                    <div className="mt-6" style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                      <Spinner color="info" size="xl"/>
                      <p className="text-slate-100 font-regular text-xl mt-6 text-center">Changing voice to <span className="font-medium">{VOICES.find(v => v.id == newVoice).text}</span></p>
                      <Button gradientMonochrome="failure" className="w-fit mt-4" onClick={() => {
                        controller.abort()
                        setChangingVoice(false)
                      }}>Cancel</Button>
                    </div>
                    
                    
                  </>
                  : <>
                  <p className="text-slate-100 font-medium text-xl mt-6">Properties</p>
                    <SliderSection label="Volume" min={0} max={1} value={voiceoverSettings.volume} setValue={(v) => {
                      setStatePropValue(voiceoverSettings, setVoiceoverSettings, "volume", v)
                    }}/>

                    <p className="text-slate-100 font-medium text-xl mt-6">Change voice</p>
                    <DropdownSelectSection label="New voice" options={VOICES} value={newVoice || currentVoice} setValue={(v) => {
                      setNewVoice(v.id)
                    }}/>

                    <Button disabled={!newVoice || newVoice == currentVoice || changingVoice} gradientMonochrome="success" className="w-fit mt-4" onClick={() => {
                      setChangingVoice(true)
                      regenerateSpeech(script, newVoice, setSpeech, setRawTranscription, setVideoDuration).then(() => {
                        setChangingVoice(false)
                        setCurrentVoice(newVoice)
                      })
                      
                    }}>Change</Button>
                  </>
              }
              
            </>
            : openedPropertiesWindow == "music"
            ? <>
              <div className="flex flex-row">
                <BsMusicNoteBeamed color="white" size="30"/>
                <h2 className="text-slate-100 font-bold ml-4 text-xl mb-4">Music</h2>
              </div>

              <fieldset>
                <div className="flex items-center gap-2 mb-2">
                  <Radio id="m-none" name="music" onChange={(e) => {
                    if(e.target.value) {
                      setStatePropValue(musicSettings, setMusicSettings, "type", "none")
                    }
                  }}/>
                  <Label htmlFor="m-none" className="text-slate-400 text-lg">None</Label>
                </div>
                <div className={`flex items-center gap-2 mb-2 ${musicSettings.type == "tiktok-preselected" ? "mb-0" : ""}`}>
                  <Radio defaultChecked id="m-tiktok" name="music" onChange={(e) => {
                    if(e.target.value) {
                      setStatePropValue(musicSettings, setMusicSettings, "type", "tiktok-preselected")
                    }
                  }}/>
                  <Label htmlFor="m-tiktok" className="text-slate-400 text-lg">TikTok Preselected</Label>
                </div>
                {
                  musicSettings.type == "tiktok-preselected"
                    ? <DropdownSelectSection label="Sound" options={SOUNDS.map(sound => {return {text: sound.name, author: sound.author, id: sound.name}})} value={musicSettings.tiktokSoundSelected.name} setValue={(v) => {
                      setStatePropValue(musicSettings, setMusicSettings, "tiktokSoundSelected", SOUNDS.find(x => x.name == v.text))
                    }}/>
                    : null
                }
                <div className={`flex items-center gap-2 mb-2 ${musicSettings.type == "tiktok-preselected" ? "mt-4" : ""}`}>
                  <Radio id="m-custom" name="music" onChange={(e) => {
                    if(e.target.value) {
                      setStatePropValue(musicSettings, setMusicSettings, "type", "custom")
                    }
                  }}/>
                  <Label htmlFor="m-custom" className="text-slate-400 text-lg">Custom</Label>
                </div>
                {
                  musicSettings.type == "custom"
                    ? <FileInput color="grey" sizing="sm" id="custom-sound-upload" onChange={(e) => {
                      const fileURL = URL.createObjectURL(e.currentTarget.files[0]);

                      setStatePropValue(musicSettings, setMusicSettings, "customSound", fileURL)
                    }}/>
                    : null
                }
              </fieldset>

              <p className="text-slate-100 font-medium text-xl mt-6">Properties</p>
              <SliderSection label="Volume" min={0} max={1} value={musicSettings.volume} setValue={(v) => {
                setStatePropValue(musicSettings, setMusicSettings, "volume", v)
              }}/>
              
            </>
            : openedPropertiesWindow == "images"
            ? <>
              <div className="flex flex-row items-center justify-between mb-4">
                <div className="flex flex-row">
                  <BsFillImageFill color="white" size="30"/>
                  <h2 className="text-slate-100 font-bold ml-4 text-xl ">{ selectedElement ? "Image (selected)" : "Images" }</h2>
                </div>
                {
                  selectedElement 
                    ? <IoClose style={{cursor: "pointer"}} size="20" color="white" onClick={() => {
                      setSelectedElement(null)
                    }}/>
                    : null
                }
              </div>
              
              {
                selectedElement
                  ? <>
                    <fieldset>
                      <div className="flex items-center gap-2 mb-2">
                        <Radio checked={selectedElement[0].imageType == "ai"} id="i-ai" name="image" onChange={(e) => {
                          if(e.target.value) {
                            updateSelectedImage({
                              imageType: "ai"
                            })
                          }
                        }}/>
                        <Label htmlFor="i-ai" className="text-slate-400 text-lg">AI</Label>
                      </div>
                      {
                        selectedElement[0].imageType == "ai"
                          ? <>
                            <img src={selectedElement[0].imageUrl}/>
                            <Textarea value={selectedElement[0].imagePrompt} placeholder="AI image prompt" className="mt-4" lines={4} onChange={(e) => {
                              updateSelectedImage({imagePrompt: e.target.value})
                            }}/>
                            <Button disabled={changingImage} gradientMonochrome="success" className="w-fit mt-4" onClick={() => {
                              setChangingImage(selectedElement[0])
                              regenerateImage(selectedElement[0].imagePrompt).then(imageUrl => {
                                if(imageUrl) {
                                  updateSelectedImage({imageUrl})
                                  setChangingImage(false)
                                } else {
                                  alert("Something went wrong. Your image is most likely NSFW.")
                                }
                                
                              })
                            }}>Generate</Button>
                          </>
                          : null
                      }
                      <div className="flex items-center gap-2 mb-2 mt-3">
                        <Radio checked={selectedElement[0].imageType == "custom"} id="i-custom" name="image" onChange={(e) => {
                          if(e.target.value) {
                            updateSelectedImage({
                              imageType: "custom"
                            })
                          }
                        }}/>
                        <Label htmlFor="i-custom" className="text-slate-400 text-lg">Custom</Label>
                      </div>
                      {
                        selectedElement[0].imageType == "custom"
                          ? <FileInput color="grey" sizing="sm" id="custom-image-upload" onChange={(e) => {
                            const fileURL = URL.createObjectURL(e.currentTarget.files[0]);

                            updateSelectedImage({
                              customImageUrl: fileURL
                            })
                          }}/>
                          : null
                      }
                    </fieldset>
                  </>
                  : <>
                  <p className="text-slate-100 font-medium text-xl mt-6">Animation</p>
                    <DropdownSelectSection label="Animation" options={[{text: "None", id: "none"}, {text: "Zoom out", id: "zoom-out"}]} value={imageSettings.animation} setValue={(v) => {
                      setStatePropValue(imageSettings, setImageSettings, "animation", v.id)
                    }}/>
                  </>
              }
              
            </>
            : null
        }
        
        
      </div>
      <div style={{flexGrow: 1, height: "100%"}}>
        <Player
          style={{width: "100%", height: "100%"}}
          component={AdRenderer}
          durationInFrames={videoDuration * fps}
          fps={fps}
          compositionWidth={1080}
          compositionHeight={1920}
          id="test-render"
          controls
          autoPlay
          loop
          inputProps={{imageSettings, musicSettings, voiceoverSettings, subtitlesSettings, rawTranscription, speech, slides, currentFrameRef, setCurrentFrameRef}}
          ref={playerRef}
        />
      </div>
      
    </div>
    <div style={{height: "300px"}} className="bg-gray-900 flex relative z-50">
      <div className="h-full w-96 border-r-2 border-gray-400 pt-2 pb-2 pl-4 pr-4">
        <p className="h-6"></p>
        <TimelineLabel setSelectedElement={setSelectedElement} type="subtitles" setOpenedPropertiesWindow={setOpenedPropertiesWindow}/>
        <TimelineLabel setSelectedElement={setSelectedElement} type="images" setOpenedPropertiesWindow={setOpenedPropertiesWindow}/>
        <TimelineLabel setSelectedElement={setSelectedElement} type="voiceover" setOpenedPropertiesWindow={setOpenedPropertiesWindow}/>
        <TimelineLabel setSelectedElement={setSelectedElement} type="music" setOpenedPropertiesWindow={setOpenedPropertiesWindow}/>

      </div>
      <div className="pb-2 grow flex flex-col relative overflow-hidden" onPointerDown={(event) => {
        setStartingTimelineOffsetSeconds(timelineOffsetSeconds)
        setStartingMousePosition(event.clientX)
      }} onClick={event => {
        if(startingMousePosition == event.clientX) {
          let box = event.currentTarget.getBoundingClientRect()
          let seconds = getSecondsFromPosition(event.clientX - box.left)
          playerRef.current.seekTo(seconds * fps)  
        }
      }}>
        <TimelineTimestamps {...{getTimelinePosition, timelineZoom, timelineOffsetSeconds}}/>
        <TimelineSection type="subtitles" {...{setOpenedPropertiesWindow, setSelectedElement, selectedElement, musicSettings, speech, getTimelinePosition, rawTranscription}}/>
        <TimelineSection type="image" {...{setOpenedPropertiesWindow, setSelectedElement, selectedElement, slides, musicSettings, speech, getTimelinePosition, rawTranscription}}/>
        <TimelineSection type="voiceover" {...{setOpenedPropertiesWindow, setSelectedElement, selectedElement, musicSettings, speech, getTimelinePosition, rawTranscription, videoDuration, timelineZoom}}/>
        <TimelineSection type="music" {...{setOpenedPropertiesWindow, setSelectedElement, selectedElement, musicSettings, speech, getTimelinePosition, rawTranscription, videoDuration, timelineZoom}}/>
        <div style={{zIndex: 10, height: "100%", width: "2px", backgroundColor: "white", transform: "translateX(-1px)", position: "absolute", top: "0px", left: getTimelinePosition(currentFrameRef.current / fps)}} ref={videoCursorRef}/>
      </div>
    </div>
    <Button gradientMonochrome="purple" style={{ display: exportOpened ? "none" : "flex", position: "absolute", right: "30px", top: "30px", alignItems: "center" }} size="xl" className="flex flex-row items-center" onClick={() => {
      setExportOpened(true)
    }}>
      <div className="flex flex-row items-center">
        <FaClapperboard className="mr-3"/>
        <p>Export</p>
      </div> 
    </Button>
    <div style={{display: exportOpened ? "flex" : "none", position: "absolute", right: "30px", top: "30px", borderRadius: "8px"}} className="bg-gray-800 p-3 pl-4 pr-4 w-72 flex-col">
      <div className="flex w-full justify-between flex-row items-center">
        <p className="font-bold text-white">{currentlyExporting ? "EXPORTING..." : "EXPORT"}</p>
        <IoClose color="white" className="cursor-pointer" size={24} onClick={() => {
          setExportOpened(false)
        }}/>
      </div>
      {
        currentlyExporting
          ? <>
            <p className="text-center text-white text-sm mt-4" style={{opacity: "0.9"}}>
              This should take less than a minute...
            </p>
            <Button color="failure" className="mt-6 mb-1 flex flex-row items-center" onClick={() => {
              setCurrentlyExporting(false)
              controller.abort()
            }}>Cancel Export</Button>
          </>
          : <>
            <p className="text-center text-white text-sm mt-4" style={{opacity: "0.9"}}>
              The export is done on our servers and usually takes under a minute.
            </p>
            <Button gradientMonochrome="purple" className="mt-6 mb-1 flex flex-row items-center" onClick={() => {
              setCurrentlyExporting(true)
              renderVideo(signal).then(video => {
                saveFile(video, "ai-video.mp4")
                setCurrentlyExporting(false)
              })
            }}>
              
              <div className="flex flex-row items-center">
                <BsCloudDownloadFill className="mr-3"/>
                <p>Export</p>
              </div> 
            </Button>
          </>
      }
      
      
    </div>
  </div>     
}            

function regenerateSpeech(script, voice, setSpeech, setRawTranscription, setVideoDuration) {
  return new Promise((resolve, reject) => {
    const TEST_TEXT = script
    console.log(JSON.stringify({
      text: TEST_TEXT.map(x => x.text).join(" "),
      voice
    }))
    fetch(apiUrl + "api/generate-speech", {
      method: "POST",
      body: JSON.stringify({
        text: TEST_TEXT.map(x => x.text).join(" "),
        voice
      }),
      signal,
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true,
      credentials: "include"
    }).then(async data => {
      // console.log(data.text())
      let audioBlob = await data.blob()
      console.log(audioBlob)
      let audioUrl = URL.createObjectURL(audioBlob)
      console.log(audioUrl)
      setSpeech(audioUrl)
      let audio = new Audio(audioUrl)
      audio.addEventListener("loadedmetadata", () => {
        //setVideoDuration(Math.ceil(audio.duration))
        setVideoDuration(Math.ceil(audio.duration))
      })
      //new Audio(audioUrl).play();

      // send to get transcription
      const formData = new FormData();
      formData.append("ai-speech", audioBlob)
      formData.append("transcript", TEST_TEXT.map(x => x.text).join(" "))
      let rawRawTranscription = await fetch(apiUrl + "api/generate-transcription", {
        method: "POST",
        body: formData,
        signal,
        withCredentials: true,
        credentials: "include"
      })

      let rawTranscription = await rawRawTranscription.json()
      rawTranscription = rawTranscription.transcription.words
      setRawTranscription(rawTranscription.map(x => {
        return {
          ...x,
          id: generateId()
        }
      }))

      let previousEndingTime = 0
      let previousWordCount = 0
      let slides = TEST_TEXT.map((slide, index) => {
        // let lastWord = getLastWord(slide.text)
        let lastWordTranscripted = rawTranscription[Math.min(rawTranscription.length-1, previousWordCount + countWords(slide.text) - 1)] // rawTranscription.find(x => x.word == lastWord)
        previousWordCount += countWords(slide.text)
        let endingTime = lastWordTranscripted.end + 0.1
        let newSlide = {
          ...slide,
          start: previousEndingTime,
          end: endingTime
        }
        if(index == TEST_TEXT.lenth - 1) {

        }
        previousEndingTime = endingTime - 0.1
        
        return newSlide
      })

      resolve()
      
    })
  })
  

}

function TimelineSection({setOpenedPropertiesWindow, setSelectedElement, selectedElement, type, rawTranscription, getTimelinePosition, speech, videoDuration, timelineZoom, musicSettings, slides}) {
  let color = null
  let contents = null
  
  const [wavesurfer, setWavesurfer] = useState(null)


  useEffect(() => {
    if(wavesurfer) {
      if(type == "voiceover") {
        //ws.load(speech)
      } else {
        if(musicSettings.type == "tiktok-preselected") {
          wavesurfer.load("/sounds/" + musicSettings.tiktokSoundSelected.path)
        } else {
          if(musicSettings.customSound) {
            wavesurfer.load(musicSettings.customSound)
          }
        }
      }
    }
    
  }, [wavesurfer, musicSettings.type, musicSettings.tiktokSoundSelected, musicSettings.customSound])
  

  const onReady = (ws) => {
    setWavesurfer(ws)
    if(type == "voiceover") {
      ws.load(speech)
    } else {
      if(musicSettings.type == "tiktok-preselected") {
        ws.load("/sounds/" + musicSettings.tiktokSoundSelected.path)
      } else {
        if(musicSettings.customSound) {
          wavesurfer.load("/sounds/" + musicSettings.customSound)
        }
      }
    }
    
    ws.on("ready", () => {
      //console.log("WE READY BOYS WHERE WE LANDING")
    })
  }
  useEffect(() => {
    if(wavesurfer) {
      wavesurfer.setOptions({minPxPerSec: SECONDS_DISTANCE*timelineZoom})
    }
  }, [timelineZoom, wavesurfer])

  useEffect(() => {
    if(wavesurfer && type == "voiceover") {
      wavesurfer.load(speech)
    }
  }, [speech, wavesurfer])

  if(type == "subtitles") {
    color = "bg-blue-600"
    contents = rawTranscription.map((word, index) => {
      let startX = getTimelinePosition(word.start)
      let endX = getTimelinePosition(word.end)
      let width = endX - startX
      if(startX > window.innerWidth) return
      if(endX < 0) return
  
      let isSelected = selectedElement && selectedElement.find(x => {return x.id == word.id})

      return <div key={index} className={`${color} h-full rounded-md overflow-hidden cursor-pointer`} style={{width: `${width - 4}px`, position: "absolute", left: `${startX + 2}px`, display: "flex", alignItems: "center", justifyContent: "center", boxSizing: "border-box", zIndex: isSelected ? 3 : 1, borderWidth: isSelected ? 2 : null, borderColor: isSelected ? "white" : "rgba(255, 255, 255, 0.5)"}} onMouseOver={(e) => {
        e.currentTarget.style.borderWidth = "2px"
        e.currentTarget.style.zIndex = "2"
      }} onMouseOut={(e) => {
        if(!isSelected) {
          e.currentTarget.style.borderWidth = "0px"
          e.currentTarget.style.zIndex = "1"
        }
      }} onClick={() => {
        if(isSelected) {
          setSelectedElement(null)
        } else {
          setSelectedElement([word])
          console.log(rawTranscription)
          setOpenedPropertiesWindow("subtitles")
        }
        
      }}>
        <p className="text-slate-100/50 select-none	Z">
          {word.word}
        </p>
      </div>
    })
  } else if(type == "image") {
    color = "bg-orange-500"
    contents = slides.map((slide, index) => {
      let startX = getTimelinePosition(slide.start)
      let endX = getTimelinePosition(slide.end)
      let width = endX - startX
      if(startX > window.innerWidth) return
      if(endX < 0) return
  
      let isSelected = selectedElement && selectedElement.find(x => {return x.id == slide.id})
      let imageUrl = slide.imageType == "ai" ? slide.imageUrl : (slide.customImageUrl ? slide.customImageUrl : null)

      return <div key={index} className={`${color} h-full rounded-md overflow-hidden cursor-pointer`} style={{width: `${width - 4}px`, position: "absolute", left: `${startX + 2}px`, display: "flex", alignItems: "center", justifyContent: "center", borderWidth: isSelected ? 2 : null, borderColor: isSelected ? "white" : "rgba(255, 255, 255, 0.5)"}} onMouseOver={(e) => {
        e.currentTarget.style.borderWidth = "2px"
        e.currentTarget.style.zIndex = "2"
      }} onMouseOut={(e) => {
        if(!isSelected) {
          e.currentTarget.style.borderWidth = null
          e.currentTarget.style.zIndex = "1"
        }
      }} onClick={() => {
        if(isSelected) {
          setSelectedElement(null)
        } else {
          setSelectedElement([slide])
          setOpenedPropertiesWindow("images")
        }
      }}>
      {
        imageUrl
          ? <img src={imageUrl} style={{width: "30px", height: "30px", userSelect: "none", pointerEvents: "none"}}/>
          : null
      }
      </div>
    })
  } else if(type == "voiceover") {
    color = "bg-green-600"
    let startX = getTimelinePosition(0)
    let endX = getTimelinePosition(videoDuration)
    let width = endX - startX

    contents = <div id="wavesurf-container" className={`${color} h-full rounded-md overflow-hidden cursor-pointer`} style={{width: `${width - 4}px`, position: "absolute", left: `${startX + 2}px`, display: "flex", alignItems: "center", borderColor: "rgba(255, 255, 255, 0.5)"}} onMouseOver={(e) => {
      e.currentTarget.style.borderWidth = "2px"
      e.currentTarget.style.zIndex = "2"
    }} onMouseOut={(e) => {
      e.currentTarget.style.borderWidth = "0px"
      e.currentTarget.style.zIndex = "1"
    }}>
      <WaveSurfer
        height={40}
        waveColor="rgba(255, 255, 255, 0.5)"
        onMount={onReady}
        fillParent={false}
        minPxPerSec={(SECONDS_DISTANCE*timelineZoom)}
        container="#wavesurf-container"
        interact={false}
        hideScrollbar={true}
      />
    </div>
  } else if(type == "music") {
    color = "bg-green-800"
    let startX = getTimelinePosition(0)
    let endX = getTimelinePosition(videoDuration)
    let width = endX - startX

    if(musicSettings.type == "none") {
      contents = null
    } else if(musicSettings.type == "tiktok-preselected") {
      contents = <div id="wavesurf-container-music" className={`${color} h-full rounded-md overflow-hidden cursor-pointer`} style={{overflow: "hidden", width: `${width - 4}px`, position: "absolute", left: `${startX + 2}px`, display: "flex", alignItems: "center", borderColor: "rgba(255, 255, 255, 0.5)"}} onMouseOver={(e) => {
        e.currentTarget.style.borderWidth = "2px"
        e.currentTarget.style.zIndex = "2"
      }} onMouseOut={(e) => {
        e.currentTarget.style.borderWidth = "0px"
        e.currentTarget.style.zIndex = "1"
      }}>
        <WaveSurfer
          style={{transform: "translateY(5px)"}}
          height={40}
          waveColor="rgba(255, 255, 255, 0.5)"
          onMount={onReady}
          fillParent={false}
          minPxPerSec={(SECONDS_DISTANCE*timelineZoom)}
          container="#wavesurf-container-music"
          interact={false}
          hideScrollbar={true}
        />
      </div>
    } else if(musicSettings.type == "custom") {
      if(musicSettings.customSound) { /*  border-gray-900 border-2 */
        contents = <div id="wavesurf-container-music" className={`${color} h-full rounded-md overflow-hidden cursor-pointer`} style={{overflow: "hidden", width: `${width - 4}px`, position: "absolute", left: `${startX + 2}px`, display: "flex", alignItems: "center", borderColor: "rgba(255, 255, 255, 0.5)"}} onMouseOver={(e) => {
          e.currentTarget.style.borderWidth = "2px"
          e.currentTarget.style.zIndex = "2"
        }} onMouseOut={(e) => {
          e.currentTarget.style.borderWidth = "0px"
          e.currentTarget.style.zIndex = "1"
        }}>
          <WaveSurfer
            style={{transform: "translateY(5px)"}}
            height={40}
            waveColor="rgba(255, 255, 255, 0.5)"
            onMount={onReady}
            fillParent={false}
            minPxPerSec={(SECONDS_DISTANCE*timelineZoom)}
            container="#wavesurf-container-music"
            interact={false}
            hideScrollbar={true}
          />
        </div>
      } else {
        contents = null
      }
    }
  }

  

  return <div className="h-12 relative overflow-hidden mb-2  bg-opacity-5"> {/* bg-gray-100 */}
    {
      contents
    }
  </div>
  /* <div className={`${color} h-full rounded-md overflow-hidden cursor-pointer border-gray-900 border-2`} style={{width: `${width - 4}px`, position: "absolute", left: `${startX + 2}px`, display: "flex", alignItems: "center", justifyContent: "center"}}>
          <p className="text-slate-100/50 select-none	Z">
            {word.word}
          </p>
        </div> */
}

function TimelineTimestamps({getTimelinePosition, timelineZoom, timelineOffsetSeconds}) {
  let [s, setS] = useState(0)


  window.addEventListener("resize", (event) => {
    setS(window.innerWidth)
  })
  return <div className="mb-2 h-6 relative overflow-hidden">
    {
      getNFromZoom(timelineZoom, timelineOffsetSeconds).map(n => {
        let x = getTimelinePosition(n)
        return <p key={n} className="select-none" style={{position: "absolute", left: `${x}px`, color: "white", top: 5, opacity: 0.5, pointerEvents: "none"}}>{secondsToTime(n)}</p>
      })
    }
  </div>
}

function getNFromZoom(zoom, timelineOffsetSeconds, s = window.innerWidth) {
  let toReturn = Array.from(Array(Math.ceil((s / SECONDS_DISTANCE) / zoom)).keys())
  toReturn = toReturn.map(x => {return Math.ceil(x + timelineOffsetSeconds)})

  let removeEachTh = Math.ceil(SECOND_ESTIMATED_WIDTH / (SECONDS_DISTANCE * zoom))
  toReturn = toReturn.filter(n => {
    return !(n % removeEachTh) && n >= 0
  })


  return toReturn
}

function TimelineLabel({setSelectedElement, type, setOpenedPropertiesWindow}) {
  let iconComponent = null
  let text = null

  let iconProps = {style: {color: "rgba(255, 255, 255, 0.8)"}, size:22}
  if(type == "subtitles") {
    iconComponent = <BsChatRightTextFill {...iconProps}/>
    text = "Subtitles"
  } else if(type == "images") {
    iconComponent = <BsFillImageFill {...iconProps}/>
    text = "Images"
  } else if(type == "voiceover") {
    iconComponent = <BsMicFill {...iconProps}/>
    text = "Voice-over"
  } else if(type == "music") {
    iconComponent = <BsMusicNoteBeamed {...iconProps}/>
    text = "Music"
  }

  return <div className="w-full h-12 bg-gray-900 rounded-md border-slate-600 border-2 cursor-pointer flex pl-4 items-center mb-2 last:mb-0" onClick={() => {
    setOpenedPropertiesWindow(type)
    setSelectedElement(null)
  }}>
    {iconComponent}
    <p style={{color: "rgba(255, 255, 255, 0.8"}} className="text-lg ml-4">{text}</p>
  </div>
}

function countWords(s){
  s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
  s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
  s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
  return s.split(' ').filter(function(str){return str!="";}).length;
  //return s.split(' ').filter(String).length; - this can also be used
}

function secondsToTime(seconds) {
  var minutes = Math.floor(seconds / 60);
  var remainingSeconds = seconds % 60;
  var formattedTime = minutes + ":" + (remainingSeconds < 10 ? "0" : "") + remainingSeconds;
  return formattedTime;
}

// Create a cache object to store sound durations
const soundDurationCache = {};

// Object to store promises for ongoing duration requests
const soundDurationPromises = {};

// Async function to get the duration of a sound
async function getSoundDuration(path) {
  return new Promise((resolve, reject) => {
    const audio = new Audio(path);

    audio.addEventListener('loadedmetadata', () => {
      const duration = Math.floor(audio.duration);
      resolve(duration);
    });

    audio.addEventListener('error', reject);

    // Start loading the audio
    audio.preload = 'metadata';
    audio.load();
  });
}

// Async function to get or calculate and cache sound duration
async function getOrCacheSoundDuration(sound) {
  const path = sound;
  
  if (soundDurationCache[path]) {
    return soundDurationCache[path];
  }

  if (soundDurationPromises[path]) {
    return soundDurationPromises[path];
  }

  const durationPromise = getSoundDuration(path)
    .then(duration => {
      soundDurationCache[path] = duration;
      delete soundDurationPromises[path]; // Remove the promise once resolved
      return duration;
    })
    .catch(error => {
      delete soundDurationPromises[path]; // Remove the promise if there's an error
      throw error;
    });

  soundDurationPromises[path] = durationPromise;
  return durationPromise;
}

function regenerateImage(prompt) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl + "api/generate-line-image", {
      method: "POST",
      body: JSON.stringify({
        prompt: prompt
      }),
      headers: {
        "Content-Type": "application/json",
        "Accept": "image/png"
      },
      withCredentials: true,
credentials: "include"
    }).then(async x => {
      let blob = await x.blob()
      if(blob.type == "application/json") {
        resolve(null)
      } else {
        let tmp_path = URL.createObjectURL(blob)
        resolve(tmp_path)
      }
      
    })
  })
  
}

function generateId() {
  return "id" + Math.random().toString(16).slice(2)
}

function saveFile(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0)
  }
}