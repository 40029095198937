"use client"

import { useState, useEffect, useRef } from "react"
import { useThemeMode } from 'flowbite-react';

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import VideoEditorHandler from "./Components/VideoEditorHandler.js"
import VideoGeneratorInput from "./Components/VideoGeneratorInput.js"
import VideoGeneratorProgress from "./Components/VideoGeneratorProgress.js"
import VideoGeneratorError from "./Components/VideoGeneratorError.js"
import {apiUrl} from "./constants.js"
import {GoogleLoginPreset} from "./util.js"
import { BsArrowDownShort, BsArrowRightShort } from "react-icons/bs";
import { Modal } from "flowbite-react"



export default function Home() {
  const [user, setUser] = useState(null)
  const {mode, setMode} = useThemeMode();
  
  const [stage, setStage] = useState("home")
  const [script, setScript] = useState(null)
  const [update, setUpdate] = useState(1)
  const [startingNow, setStartingNow] = useState(false)

  const videoEditorHandlerRef = useRef()
  const videoGeneratorInputRef = useRef()
  const videoGeneratorErrorRef = useRef()


  useEffect(() => {
    console.log("this gets ran??")
    setMode("dark")

    fetch(apiUrl + "api/user-data", {
      method: "GET",
      withCredentials: true,
      credentials: "include",
    }).then(async rawData => {
      const data = await rawData.json()
      if(data.status == "ok") {
        setUser(data.user)
      } else {
        setUser(null)
      }
    })
  }, [])




  
  return (
    <GoogleOAuthProvider clientId="643420478972-h86m5r4f64859jbs2fmoerfhk8vg0p2o.apps.googleusercontent.com">
      <>
        <div className="bg-gray-900 w-full h-full flex flex-col items-center overflow-auto scroll-smooth" style={{display: stage == "editor" ? "none" : "flex", overflowX: "hidden"}}>
          <div className="w-full max-w-6xl flex flex-col mt-6">
            
            <div className="flex flex-row justify-between">
              <a href="/"><img src="logo.svg" className="h-12 w-fit" /></a>
              <div className="flex flex-row items-center">
                <a className="font-medium text-white/60 flex h-full items-center justify-center text-lg ml-10" href="/pricing" style={user ? {marginRight: "0px"} : {marginRight: "30px"}}>Pricing</a>
                {/* <a className={"font-medium text-white/60 flex h-full items-center justify-center text-lg ml-10" + (!user ? " mr-8" : "")} href="/showcase">Show case</a> */}
                {
                  user
                    ? <a className="font-medium text-white/60 flex h-full items-center justify-center text-lg ml-10" href="/dashboard">Dashboard</a>
                    : <GoogleLoginPreset setUser={setUser}/>
                }
                
              </div>
            </div>
            <div className="flex flex-row mb-24">
              <div className="flex flex-col w-3/5">
                <h1 className="text-white text-5xl text-left font-bold mt-20" style={{lineHeight: "140%"}}>Create short-form content with AI in <span className="relative nowrap"><img alt="" src="/ui/seconds-highlight.png" style={{userSelect: "none", position: "absolute", top: 0, left: 0, transform: "scale(1.1)"}}/><span style={{position: "relative", zIndex: 2}}>seconds</span></span></h1>
                <p className="text-white/70 text-xl text-left mt-8" style={{lineHeight: "160%"}}>
                  Etereel removes the hustle of editing your videos clip by clip. Transform your script into short-form content that is ready to be posted onto any platform of your choice.
                </p>
                <button className="mt-12 w-fit p-4 pl-8 font-semibold text-white flex flex-row items-center" style={{borderRadius: "1000px", background: "linear-gradient(45deg, #0061FF, #23339F)"}} onClick={() => {
                  setStartingNow(true)
                }}>
                  Start now (free)
                  <BsArrowRightShort className="ml-4" size="28"/>
                </button>
              </div>
              <div className="pt-24 grow h-full flex flex-col items-center justify-end">
                <iframe width="416" height="234" src="https://www.youtube.com/embed/Dapiop8RQ9Y?si=_I76OwGTNSFSXQiN&hd=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen style={{marginBottom: "50px", borderRadius: "10px"}}></iframe>
                <a href="https://www.producthunt.com/posts/etereel?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-etereel" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=459641&theme=light" alt="Etereel - Transform&#0032;a&#0032;script&#0032;into&#0032;short&#0045;form&#0032;videos&#0032;with&#0032;AI | Product Hunt" style={{width: "250px", height: "54px"}} width="250" height="54" /></a>
              </div>
            </div>
            {/* 
            <p className="text-white/70 text-xl text-center mt-4 mb-12">Our AI video generator allows you to create & edit shorts that go <span className="text-white bg-purple-500 p-3 pt-1 pb-1 rounded-xl font-semibold ml-2">VIRAL</span></p>
            <div style={{display: "flex", justifyContent: "center"}}>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/Dapiop8RQ9Y?si=_I76OwGTNSFSXQiN&hd=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen style={{marginBottom: "50px", borderRadius: "10px"}}></iframe>
            </div>
            <div className="w-full flex flex-row justify-center items-center">
              <a href="#video-input" className="p-2 pl-6 pr-6 bg-purple-500 rounded-3xl flex justify-center text-white text-center font-medium text-xl mb-4">
                Start now
                <BsArrowDownShort size={28} style={{marginLeft: "10px"}}/>
              </a>
            </div> */}

            
            
          </div>

          <VideoGeneratorError setUser={setUser} ref={videoGeneratorErrorRef}/>
          {
            videoGeneratorInputRef.current && <VideoGeneratorProgress videoGeneratorInputRef={videoGeneratorInputRef} generatingVideoStage={videoGeneratorInputRef.current.generatingVideoStage} generatingVideo={videoGeneratorInputRef.current.generatingVideo} script={script}/>
          }
          <div style={{flexShrink: 0, width: "100vw", height: "350px", backgroundColor: "rgba(0, 0, 0, 0.2)", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "50px"}}>
            <p className="text-gray-400 mb-3 text-lg"><span className="text-red-500">1hr</span> to create a voice-over</p>
            <p className="text-gray-400 mb-3 text-lg"><span className="text-red-500">1hr</span> to find & edit clips</p>
            <p className="text-gray-400 mb-3 text-lg"><span className="text-red-500">1hr</span> to create the subtitles</p>
            <p className="text-gray-400 mb-3 text-lg"><span className="text-red-500">∞ hrs</span> spent overthinking</p>
            <p className="text-white text-xl font-semibold mt-3">We will let you do it in minutes.</p>
          </div>


          <div className="w-full max-w-6xl flex flex-col mt-6" style={{marginBottom: "1000px"}}>
            <h2 className="text-center pt-10 text-white text-4xl font-semibold mb-12">How does it work?</h2>
            <div className="flex flex-row w-full justify-between">
              <div className="flex flex-col items-center w-64 text-center text-white">
                <img src="/how-it-works/1.png" className="w-52"/>
                <h3 className="mt-4 font-semibold text-xl text-gray-200">Write your script</h3>
                <p className="mt-1 text-gray-300" style={{fontSize: "15px"}}>
                  Anything can work. Want a video about a story, a joke or an opinion? Just write it in. Etereel also offers a <span className="text-blue-500">product ad</span> option.
                </p>
              </div>
              <div className="flex flex-col items-center w-64 text-center text-white">
                <img src="/how-it-works/2.png" className="w-52"/>
                <h3 className="mt-4 font-semibold text-xl text-gray-200">Wait <span className="text-blue-500">*1*</span> minute</h3>
                <p className="mt-1 text-gray-300" style={{fontSize: "15px"}}>
                Etereel allows you to generate videos in less than 1 minute, and you can hide the window while you wait.
                </p>
              </div>
              <div className="flex flex-col items-center w-64 text-center text-white">
                <img src="/how-it-works/3.png" className="w-52"/>
                <h3 className="mt-4 font-semibold text-xl text-gray-200" style={{"whiteSpace": "nowrap", "overflow": "hidden"}}>Make some edits (optional)</h3>
                <p className="mt-1 text-gray-300" style={{fontSize: "15px"}}>
                  If you want to make your video more unique, Etereel allows you to change <span className="text-blue-500">anything</span> about your video before you export it.
                </p>
              </div>
              <div className="flex flex-col items-center w-64 text-center text-white">
                <img src="/how-it-works/4.png" className="w-52"/>
                <h3 className="mt-4 font-semibold text-xl text-gray-200" style={{"whiteSpace": "nowrap", "overflow": "hidden"}}>Export in <span className="text-blue-500">high-res</span></h3>
                <p className="mt-1 text-gray-300" style={{fontSize: "15px"}}>
                  Etereel allows you to export your videos in <span className="text-blue-500">Full HD</span> and <span className="text-blue-500">without watermark</span>. Do it as many times as you will need.
                </p>
              </div>
            </div>

            <h2 className="text-center pt-10 text-white text-4xl font-semibold">Showcase</h2>
            <p className="text-center text-gray-200 text-lg mb-6">Click on a video to unmute</p>
            <div className="flex flex-row justify-between">
              <video onLoadedData={() => {console.log("DOING THE TASK BRO"); document.getElementById("showcase-1").setAttribute("muted", true); document.getElementById("showcase-2").setAttribute("muted", true); document.getElementById("showcase-3").setAttribute("muted", true); document.getElementById("showcase-4").setAttribute("muted", true)}} className="rounded-lg cursor-pointer" id="showcase-1" width="240" muted="true"  autoPlay disableRemotePlayback  loop="true" onClick={(event) => {
                const newValue = !event.currentTarget.muted
                event.currentTarget.muted = newValue
                document.getElementById("showcase-1").setAttribute("muted", newValue)
                document.getElementById("showcase-2").setAttribute("muted", true)
                document.getElementById("showcase-2").muted = true
                document.getElementById("showcase-3").setAttribute("muted", true)
                document.getElementById("showcase-3").muted = true
                document.getElementById("showcase-4").setAttribute("muted", true)
                document.getElementById("showcase-4").muted = true
              }}>
                <source src="https://storage.googleapis.com/etereel/1.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
              <video className="rounded-lg cursor-pointer" id="showcase-2" width="240" muted="true"  autoPlay disableRemotePlayback  loop="true" onClick={(event) => {
                const newValue = !event.currentTarget.muted
                event.currentTarget.muted = newValue
                document.getElementById("showcase-2").setAttribute("muted", newValue)
                document.getElementById("showcase-1").setAttribute("muted", true)
                document.getElementById("showcase-1").muted = true
                document.getElementById("showcase-3").setAttribute("muted", true)
                document.getElementById("showcase-3").muted = true
                document.getElementById("showcase-4").setAttribute("muted", true)
                document.getElementById("showcase-4").muted = true
              }}>
                <source src="https://storage.googleapis.com/etereel/2.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
              <video className="rounded-lg cursor-pointer" id="showcase-3" width="240" muted="true"  autoPlay disableRemotePlayback  loop="true" onClick={(event) => {
                const newValue = !event.currentTarget.muted
                event.currentTarget.muted = newValue
                document.getElementById("showcase-3").setAttribute("muted", newValue)
                document.getElementById("showcase-1").setAttribute("muted", true)
                document.getElementById("showcase-1").muted = true
                document.getElementById("showcase-2").setAttribute("muted", true)
                document.getElementById("showcase-2").muted = true
                document.getElementById("showcase-4").setAttribute("muted", true)
                document.getElementById("showcase-4").muted = true
              }}>
                <source src="https://storage.googleapis.com/etereel/3.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
              <video className="rounded-lg cursor-pointer" id="showcase-4" width="240" muted="true"  autoPlay disableRemotePlayback  loop="true" onClick={(event) => {
                const newValue = !event.currentTarget.muted
                event.currentTarget.muted = newValue
                document.getElementById("showcase-4").setAttribute("muted", newValue)
                document.getElementById("showcase-1").setAttribute("muted", true)
                document.getElementById("showcase-1").muted = true
                document.getElementById("showcase-2").setAttribute("muted", true)
                document.getElementById("showcase-2").muted = true
                document.getElementById("showcase-3").setAttribute("muted", true)
                document.getElementById("showcase-3").muted = true
              }}>
                <source src="https://storage.googleapis.com/etereel/4.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
            </div>

            <h2 className="text-center pt-20 text-white text-4xl font-semibold mb-4">What's included?</h2>
            <div className="flex flex-row mt-6 items-center">
              <div className="grow flex flex-col">
                <p className="text-gray-100 font-bold" style={{fontSize: "28px", opacity: 0.9}}>UNLIMITED CHANGES</p>
                <p className="text-white/70 text-xl mt-4" style={{lineHeight: "160%"}}>
                  Even after rendering your video, you can still make any changes you want. From changing the font, to the AI voice to even the clips themselves, everything stays very customizable. Render your video as many times as you need.
                </p>
                <img src="edits.png" style={{height: "60px", objectFit: "contain", width: "fit-content", marginTop: "20px"}}/>
              </div>
              <img src="https://storage.googleapis.com/etereel/unlimited-changes.gif" style={{width: "300px", marginLeft: "8px"}}/>
            </div>
            <div className="flex flex-row mt-6 items-center">
              <div className="grow flex flex-col">
                <p className="text-gray-100 font-bold" style={{fontSize: "28px", opacity: 0.9}}>SHORTEST GENERATION TIMES</p>
                <p className="text-white/70 text-xl mt-4" style={{lineHeight: "160%"}}>
                  We created the platform that can generate videos the fastest. A normal 20 second video will generate in under a minute which is shorter than the 8-10 minutes provided by our competitors. Also definitely shorter than what it takes humans :)
                </p>
              </div>
              <img src="/generation-times.png" style={{width: "300px", marginLeft: "8px"}}/>
            </div>

            <div style={{color: "rgba(255, 255, 255, 0.5)"}}>Contact email: asemenov.ca@gmail.com</div>

            {/* <h2 className="text-center pt-10 text-white text-4xl font-semibold mb-8" id="video-input">Generate your first video for free</h2> */}
            
          </div>


        </div>
        <VideoEditorHandler ref={videoEditorHandlerRef} update={update} show={stage == "editor"}/>
      </>   

      {
        stage == "home"
          ? <a target="_blank" href="https://www.producthunt.com/products/etereel" className="shadow-lg flex flex-row rounded-xl cursor-pointer pr-4 items-center" style={{backgroundColor: "#FF5056", position: "absolute", left: "25px", bottom: "25px"}}>
            <img src="./producthunt.svg" className="h-full m-2"/>
            <div className="ml-2 mr-2" style={{display: "flex", flexDirection: "column"}}>
              <p className="font-bold text-white text-lg" style={{marginBottom: "0px"}}>Live on Product Hunt now!</p>
              <p className="text-white text-sm" style={{marginTop: "-5px"}}>Would love your support 🫶</p>

            </div>
          </a>
          : null
      }
      

      <Modal show={true} style={{display: startingNow && stage == "home" ? "flex" : "none"}} size="2xl" popup onClose={() => {setStartingNow(false)}}>
        <Modal.Header style={{marginLeft: "18px", paddingTop: "20px", marginBottom: "10px"}}><span style={{fontSize: "22px", marginTop: "20px"}}>Generate your video</span></Modal.Header>
        <VideoGeneratorInput {...{update, setUpdate, videoEditorHandlerRef, videoGeneratorInputRef, videoGeneratorErrorRef, user, setStage, setScript}} ref={videoGeneratorInputRef}/>
      </Modal>

  </GoogleOAuthProvider>
    
  );
}