import {apiUrl} from "./constants.js"
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';



const REQUEST_IMAGES = true
function getRandomInteger() {
  return Math.floor(Math.random() * 9000000000) + 1000000000;
}

const manageScript = async (TEST_TEXT, {voiceSelected, update, setUpdate, setScript, setPicturesToGenerate, setGeneratingVideoStage, videoEditorHandlerRef, setGeneratingVideo, setStage}) => {
  setScript(TEST_TEXT);

  setPicturesToGenerate(TEST_TEXT.filter(function (x) {return x.imagePrompt != "PRODUCT_PICTURE"}));
  fetch(apiUrl + "api/generate-speech", {
    method: "POST",
    body: JSON.stringify({
      text: TEST_TEXT.map(x => x.text).join(" "),
      voice: voiceSelected
    }),
    headers: {
      "Content-Type": "application/json"
    },
    withCredentials: true,
    credentials: "include"
  }).then(async data => {
    setGeneratingVideoStage(2)
    update && setUpdate(++update)
    let audioBlob = await data.blob()
    let audioUrl = URL.createObjectURL(audioBlob)
    videoEditorHandlerRef.current.setSpeech(audioUrl)
    let audio = new Audio(audioUrl)
    console.log("LOADING METADATA")
    audio.addEventListener("loadedmetadata", () => {
      videoEditorHandlerRef.current.setVideoDuration(Math.ceil(audio.duration))
    })
    //new Audio(audioUrl).play();

    // send to get transcription
    const formData = new FormData();
    formData.append("ai-speech", audioBlob)
    formData.append("transcript", TEST_TEXT.map(x => x.text).join(" "))
    let rawRawTranscription = await fetch(apiUrl + "api/generate-transcription", {
      method: "POST",
      body: formData,
      withCredentials: true,
      credentials: "include"
    })

    let rawTranscription = await rawRawTranscription.json()
    setGeneratingVideoStage(3)
    update && setUpdate(++update)
    rawTranscription = rawTranscription.transcription.words
    videoEditorHandlerRef.current.setRawTranscription(rawTranscription.map(x => {return {...x, id: generateId(), type: "word"}}))

    let previousEndingTime = 0
    let previousWordCount = 0
    let slides = TEST_TEXT.map((slide, index) => {
      // let lastWord = getLastWord(slide.text)
      console.log(slide, index, TEST_TEXT)
      let lastWordTranscripted = rawTranscription[Math.min(rawTranscription.length-1, previousWordCount + countWords(slide.text) - 1)] // rawTranscription.find(x => x.word == lastWord)
      previousWordCount += countWords(slide.text)
      let endingTime = lastWordTranscripted.end + 0.1
      let newSlide = {
        ...slide,
        id: generateId(),
        type: "image",
        start: previousEndingTime,
        end: endingTime,
        imageType: "ai", // ai / custom
        customImageUrl: null
      }
      if(index == TEST_TEXT.lenth - 1) {

      }
      previousEndingTime = endingTime - 0.1
      return newSlide
    })

    console.log("STARTING REQUEST IMAGES")

    if(REQUEST_IMAGES) {
      const seed = getRandomInteger()
      let result = await Promise.all(slides.filter(slide => slide.imagePrompt != "PRODUCT_PICTURE").map((slide, index) => {
        return new Promise((resolve, reject) => {
          function attempt() {
            fetch(apiUrl + "api/generate-line-image", {
              method: "POST",
              body: JSON.stringify({
                prompt: slide.imagePrompt,
                seed
              }),
              headers: {
                "Content-Type": "application/json",
                "Accept": "image/png"
              },
              withCredentials: true,
              credentials: "include"
            }).then(async x => {
              let blob = await x.blob()
              if(blob.type == "text/plain") {
                attempt()
                const reader = new FileReader();
                reader.onload = function() {
                  console.log('ERROR! Blob content:', reader.result);
                };
                reader.readAsText(blob);
                return // doesnt work
              }
              let tmp_path = URL.createObjectURL(blob)
              resolve({
                ...slide,
                imageUrl: tmp_path
              })
            })
          }

          attempt()
          
        })
        
      }))
      videoEditorHandlerRef.current.setSlides(result)

    } else {
      videoEditorHandlerRef.current.setSlides(slides)
    }
    
    console.log("GENERATING VIDEO FALSE")

    setGeneratingVideo(false)
    setStage("editor")
  })
}

async function generateVideo({voiceSelected, update, setUpdate, setStage, setScript, startWithSelected, setGeneratingVideo, setGeneratingVideoStage, setGeneratedPicturesCount, setPicturesToGenerate, promptInput, videoEditorHandlerRef}) {
  const _manageScript = (s) => {
    manageScript(s, {voiceSelected, update, setUpdate, setScript, setPicturesToGenerate, setGeneratingVideoStage, videoEditorHandlerRef, setGeneratingVideo, setStage})
  }
  
  if(startWithSelected == "product") {
    setGeneratingVideo(true)
    setGeneratingVideoStage(0)
    setGeneratedPicturesCount(0)
    setPicturesToGenerate(0)
    update && setUpdate(++update)
    fetch(apiUrl + "api/new-ad", {
      method: "POST",
      body: JSON.stringify({
        productDescription: promptInput
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: "include"
    }).then(rawData => {
      rawData.json().then(data => {
        setGeneratingVideoStage(1)
        _manageScript(data.script)
      })
    })
  } else if(startWithSelected == "script") {
    setGeneratingVideo(true)
    setGeneratingVideoStage(0)
    setGeneratedPicturesCount(0)
    setPicturesToGenerate(0)
    update && setUpdate(++update)
    fetch(apiUrl + "api/new-script", {
      method: "POST",
      body: JSON.stringify({
        script: promptInput
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: "include"
    }).then(rawData => {
      rawData.json().then(data => {
        setGeneratingVideoStage(1)
        _manageScript(data.script)
      })
    })
  } else if(startWithSelected == "ai-script") {
    setGeneratingVideo(true)
    setGeneratingVideoStage(0)
    setGeneratedPicturesCount(0)
    setPicturesToGenerate(0)
    update && setUpdate(++update)
    fetch(apiUrl + "api/new-ai-script", {
      method: "POST",
      body: JSON.stringify({
        prompt: promptInput
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: "include"
    }).then(rawData => {
      rawData.json().then(data => {
        setGeneratingVideoStage(1)
        _manageScript(data.script)
      })
    })
  }
}

function generateId() {
  return "id" + Math.random().toString(16).slice(2)
}

function countWords(s){
  s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
  s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
  s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
  return s.split(' ').filter(function(str){return str!="";}).length;
  //return s.split(' ').filter(String).length; - this can also be used
}

function GoogleLoginPreset({setUser, onFinish, whiteTheme}) {
  return <GoogleLogin shape="pill" theme={whiteTheme ? "outlined" : "filled_blue"} onSuccess={(credentialResponse) => {
    const credential = credentialResponse.credential
      fetch(apiUrl + "api/auth", {
        method: "POST",
        body: JSON.stringify({
          credential
        }),
        headers: {
          'Access-Control-Allow-Origin': '*', 
          "Content-Type": "application/json"
        },
        credentials: "include",
        withCredentials: true,
      }).then(async rawData => {
        let data = await rawData.json()
        if(data.status == "ok") {
          setUser(data.user)
          onFinish()
        } else {
          alert("something wrong with auth")
          onFinish()
        }
      }).catch(error => {
        console.log(error)
        //alert("error when fetching")
      })
    }} onError={() => {
      console.log("SIGMA ERROR DETECTED")
    }}/>
}

export {generateVideo, GoogleLoginPreset}