import { useState, useRef, forwardRef, useImperativeHandle } from "react"
import { Button, Textarea, Dropdown, Modal, Card } from 'flowbite-react';
import { BsChatLeftTextFill, BsLightningChargeFill, BsFillCartFill, BsCardText, BsMicFill, BsMusicNoteBeamed, BsSliders } from "react-icons/bs";
import {generateVideo} from "../util.js"
import { SOUNDS, STYLE_PRESETS, DEFAULT_STYLE, RAW_TRANSCRIPTION_TEMPLATE, SLIDES_TEMPLATE } from "../constants.js"
import {Player} from "@remotion/player"
import AdRenderer from "../AdRenderer.js"

const VideoGeneratorInput = forwardRef(({update, setUpdate, advanced, videoEditorHandlerRef, videoGeneratorInputRef, videoGeneratorErrorRef, user, setStage, setScript}, ref) => {
  const [promptInput, setPromptInput] = useState("")
  const [generatingVideo, setGeneratingVideo] = useState(false)
  const [generatingVideoStage, setGeneratingVideoStage] = useState(0)
  const [generatedPicturesCount, setGeneratedPicturesCount] = useState(0)
  const [picturesToGenerate, setPicturesToGenerate] = useState(null)

  const [startWithSelected, setStartWithSelected] = useState("script")
  const [voiceSelected, setVoiceSelected] = useState("alloy")
  const [soundSelected, setSoundSelected] = useState(SOUNDS[9].path)
  const [stylePreset, setStylePreset] = useState('default')
  const [stylePresetsPopupOpened, setStylePresetsPopupOpened] = useState(false)

  const scriptInputRef = useRef()

  useImperativeHandle(ref, () => ({
    startWithSelected, promptInput, generatingVideo: generatingVideo ? true : false, generatingVideoStage,
    voiceSelected, soundSelected, stylePreset
  }))

  return <div className="w-full flex-col items-center pl-6 pr-6">
    {
      advanced && <div className="mt-12">
        <InputDropdown setValue={(value) => {
          setVoiceSelected(value);
        }} options={[{name: "Alloy", id: "alloy"}, {name: "Echo", id: "echo"}, {name: "Fable", id: "fable"}, {name: "Onyx", id: "onyx"}, {name: "Nova", id: "nova"}, {name: "Shimmer", id: "shimmer"}]} value={voiceSelected} name="Voice" icon={<BsMicFill color="white" size={25}/>} tooltip="The voice used for the transcript of your video. This can be changed later."/>
        <InputDropdown setValue={(value) => {
          setSoundSelected(value)
          videoEditorHandlerRef.current.setMusicSettings({
            ...videoEditorHandlerRef.current.musicSettings,
            tiktokSoundSelected: SOUNDS.find(sound => {
              return sound.path == value
            })
          })
        }} options={SOUNDS.map(sound => {return {name: sound.name, id: sound.path}})} value={soundSelected} name="Music" icon={<BsMusicNoteBeamed color="white" size={25}/>} tooltip="The background music used for your video. This can be changed later."/>
        <InputDropdown icon={<BsSliders color="white" size={25}/>} customDropdown={<div style={{cursor: "pointer", display: "flex", alignItems: "center", color: "white", paddingLeft: "28px", flexGrow: "1", justifyContent: "flex-start", backgroundColor: "#1F2937", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: "100px", borderBottomRightRadius: "100px"}} onClick={() => {
          setStylePresetsPopupOpened(true)
        }}>
          {STYLE_PRESETS[stylePreset].name}
          <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" class="ml-2 h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"></path></svg>
        </div>} name="Style preset"/>
        <hr className="mb-8"/>
      </div>
      
    }

      {
        advanced
          ? <InputDropdown setValue={setStartWithSelected} options={[{name: "Script", id: "script"}, /* {name: "AI Script", id: "ai-script"}, */ {name: "Product", id: "product"}]} value={startWithSelected} name="Start with" icon={<BsCardText color="white" size={25}/>}/>
          : null/* <h3 className="text-white text-2xl font-semibold mb-4" style={{marginTop: "-20px"}}>Generate your video</h3> *//*<div className="flex justify-center mb-4">
            <p className="text-white font-medium text-xl mr-8 flex justify-center items-center">Start with</p>
            <Button.Group>
              <Button color={startWithSelected == "script" ? "purple" : "gray"} onClick={() => {
                setStartWithSelected("script")
              }}>
                <BsChatLeftTextFill className="mr-3 h-4 w-4"/>
                Script
              </Button>
              <Button color={startWithSelected == "product" ? "purple" : "gray"} onClick={() => {
                setStartWithSelected("product")
              }}>
                <BsFillCartFill className="mr-3 h-4 w-4"/>
                Product
              </Button>
              <Button disabled color={startWithSelected == "ai-script" ? "purple" : "gray"} onClick={() => {
                setStartWithSelected("ai-script")
              }}>
                <BsLightningChargeFill className="mr-3 h-4 w-4"/>
                AI Script
              </Button>
              
              <Button disabled color={startWithSelected == "lined-script" ? "purple" : "gray"} onClick={() => {
                setStartWithSelected("lined-script")
              }}>
                <BsCardText className="mr-3 h-4 w-4"/>
                Lined script
              </Button>
            </Button.Group>
          </div>*/
      }
      
    {
      
      !advanced && <div className="flex justify-between">
        <p className="text-white mb-2 text-md">
          Your script
        </p>
        <button className="text-blue-400 text-md mb-2 hover:underline font-medium" onClick={() => {
          let text = getRandomElement([
            "No one is born hating another person because of the color of his skin or his background or his religion...",
            "America, I'm honored that you have chosen me to lead our great country. The work ahead of us will be hard, but I promise you this: I will be a President for all Americans — whether you voted for me or not. I will keep the faith that you have placed in me.",
            "Once upon a time, a young dinosaur named Trixie loved to explore her vibrant world. One day, she found a baby pterodactyl stuck in a tree. Using her long neck, Trixie gently helped it down. The grateful pterodactyl brought Trixie a delicious fruit as a thank-you gift. Trixie realized that being kind and helping others not only felt good but also brought unexpected rewards. From then on, Trixie always looked for ways to help her friends.",
            "Once upon a time, in a lush forest, there was a young squirrel named Nutty. Nutty loved to gather acorns and play with friends. One day, he saw a rabbit struggling to find food. Nutty shared his acorns with the rabbit. The next winter, when food was scarce, the rabbit returned the favor by sharing his carrots. Nutty learned that sharing and cooperation made everyone stronger and happier. From then on, Nutty always shared with his forest friends.",
            "Shrooms, colloquially known as magic mushrooms, are fungi containing psychoactive compounds like psilocybin and psilocin. Upon ingestion, these compounds interact with serotonin receptors in the brain, particularly in the prefrontal cortex. This interaction leads to alterations in perception, mood, and cognition. Users may experience vivid hallucinations, euphoria, introspection, and altered sense of time. The effects vary widely based on dosage, individual physiology, and environmental factors. Research suggests potential therapeutic applications for mental health conditions, but recreational use carries risks and legal implications.",
            "Meditation offers a profound sanctuary for the mind and body, fostering inner peace amidst life's chaos. Its practice cultivates mental clarity, reducing stress and anxiety while enhancing emotional resilience. Through focused attention and mindful awareness, meditation empowers individuals to navigate challenges with greater ease, promoting overall well-being. Regular meditation not only improves concentration and creativity but also nurtures a deeper connection to oneself and the world around us.",
            "Weightlifting utilizes external resistance for targeted muscle training, offering precise intensity control and promoting muscle hypertrophy. In contrast, calisthenics relies on body weight for functional strength, balance, and flexibility, engaging multiple muscle groups simultaneously. Weightlifting excels in muscle isolation and progression, while calisthenics emphasizes coordination and minimal equipment requirements, suitable for diverse fitness levels. Each approach offers distinct advantages, catering to individual preferences and fitness objectives.",
            "Interstellar follows a group of astronauts who embark on a journey through a wormhole near Saturn to find a new habitable planet for humanity. Driven by the imminent collapse of Earth's environment, they face immense challenges, including the effects of time dilation and the unknowns of deep space. The film explores themes of love, sacrifice, and the resilience of the human spirit against overwhelming odds. With stunning visuals and a complex narrative, Interstellar blends science fiction with emotional depth, leaving audiences pondering the mysteries of the cosmos."
          ])
          
          scriptInputRef.current.value = text
          setPromptInput(text)
        }}>
          Randomize
        </button>  
      </div>
    }

    {{
      "script": <Textarea ref={scriptInputRef} style={advanced ? {} : {resize: "none", backgroundColor: "rgba(0, 0, 0, 0.3"}} id="script" placeholder={advanced ? "Your script..." : `Your script... (press "Randomize" to get a random script)`} rows={4} onChange={(e) => {
        setPromptInput(e.target.value)
      }}/>,
      "ai-script": <Textarea placeholder="The topic of your video..." rows={4} onChange={(e) => {
        setPromptInput(e.target.value)
      }}/>,
      "product": <Textarea placeholder="The description of your product" rows={4} onChange={(e) => {
        setPromptInput(e.target.value)
      }}/>,
      "lined-script": <p>Not available yet!</p>
    }[startWithSelected]}
    
    

    {
      !advanced && <p style={{fontSize: "15px", textAlign: "center", color: "rgba(255,255,255,0.8)", marginTop: "15px"}}>Tip: to get more options for how your video is generated, do it on the <a className="text-blue-400" href="/dashboard">Dashboard</a></p>
    }

    {
      advanced && <Modal show={stylePresetsPopupOpened} size="lg" style={{borderRadius: "100px"}} popup onClose={() => {
          setStylePresetsPopupOpened(false)
        }}>
          <Modal.Header />
          <Modal.Body style={{maxHeight: "400px"}}>
            <div className="text-center flex flex-col items-center">
              <p className="mb-5 text-lg font-normal text-white font-weight-900">Choose a preset for your video</p>
              <div className="grid-cols-2 grid gap-6 overflow-auto cursor-pointer">
                {
                  Object.values(STYLE_PRESETS).map(sp => {
                    return <Card className="max-w-sm" onClick={() => {
                      setStylePreset(sp.key)
                      setStylePresetsPopupOpened(false)

                      const newStylePreset = Object.values(STYLE_PRESETS).find(stylePreset => {
                        return stylePreset.key == sp.key
                      })
                      videoEditorHandlerRef.current.setSubtitlesSettings({
                        ...videoEditorHandlerRef.current.subtitlesSettings,
                        ...newStylePreset.subtitlesSettings
                      })
                      videoEditorHandlerRef.current.setImageSettings({
                        ...videoEditorHandlerRef.current.imageSettings,
                        ...newStylePreset.imageSettings
                      })
                    }}>
                      <Player
                        style={{width: "150px", height: "200px"}}
                        component={AdRenderer}
                        durationInFrames={400}
                        fps={60}
                        compositionWidth={1080}
                        compositionHeight={1920}
                        autoPlay
                        loop
                        inputProps={{
                          preview: true,
                          imageSettings: !sp.imageSettings ? DEFAULT_STYLE.imageSettings : {...DEFAULT_STYLE.imageSettings, ...sp.imageSettings},
                          musicSettings: !sp.musicSettings ? DEFAULT_STYLE.musicSettings : {...DEFAULT_STYLE.musicSettings, ...sp.musicSettings},
                          voiceoverSettings: !sp.voiceoverSettings ? DEFAULT_STYLE.voiceoverSettings : {...DEFAULT_STYLE.voiceoverSettings, ...sp.voiceoverSettings},
                          subtitlesSettings: !sp.subtitlesSettings ? {...DEFAULT_STYLE.subtitlesSettings, fontSize: 150} : {...DEFAULT_STYLE.subtitlesSettings, ...sp.subtitlesSettings, fontSize: (sp?.subtitlesSettings?.fontSize || DEFAULT_STYLE.subtitlesSettings.fontSize) * 1.5, outlineSize: (sp?.subtitlesSettings?.outlineSize || DEFAULT_STYLE.subtitlesSettings.outlineSize) * 1.5},
                          rawTranscription: RAW_TRANSCRIPTION_TEMPLATE,
                          slides: SLIDES_TEMPLATE
                        }}
                      />
                      <p className="font-normal text-white">
                        {sp.name}
                      </p>
                    </Card>
                  })
                }
                
              </div>
            </div>
          </Modal.Body>
        </Modal>
    }
  
    

    <div className="flex items-center flex-col p-10">
      <Button gradientMonochrome="purple" id="generate-button" className="w-32 h-14 rounded-md mt-5 text-white font-bold justify-center items-center" onClick={async () => {
        if(!videoGeneratorInputRef.current.promptInput || videoGeneratorInputRef.current.promptInput.length < 10) {
          videoGeneratorErrorRef.current.setGenerateErrorOpened("short-script")
          return
        }
        if(generatingVideo) {
          return
        }
        if(!user) {
          videoGeneratorErrorRef.current.setGenerateErrorOpened("not-logged-in")
          return
        }
        if(user.balance < 100) {
          videoGeneratorErrorRef.current.setGenerateErrorOpened("out-of-balance")
          return
        }

        

        generateVideo({voiceSelected, update, setUpdate, setStage, setScript, startWithSelected: videoGeneratorInputRef.current.startWithSelected, setGeneratingVideo, setGeneratingVideoStage, setGeneratedPicturesCount, setPicturesToGenerate, promptInput: videoGeneratorInputRef.current.promptInput, videoEditorHandlerRef})
      }}>
        Generate
      </Button>

    </div>

  </div>

}) 

function InputDropdown({icon, name, tooltip, options, value, setValue, customDropdown}) {
  return <div className="flex h-12 w-full flex-row mb-8">
    <div className="bg-purple-600 w-72 flex items-center pl-6" style={{borderTopLeftRadius: "1000px", borderBottomLeftRadius: "1000px"}}>
      {icon}
      <p className="text-white ml-4 font-medium">{name}</p>
    </div>
    {
      customDropdown
        ? customDropdown
        : <Dropdown label={options.find(x => {return x.id == value}).name} style={{paddingLeft: "10px", flexGrow: "1", justifyContent: "flex-start", backgroundColor: "#1F2937", borderWidth: "2px", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: "100px", borderBottomRightRadius: "100px"}}>
          {
            options.map(option => {
              return <Dropdown.Item onClick={() => {
                setValue(option.id)
              }}>
                {option.name}
              </Dropdown.Item>
            })
          }
        </Dropdown>
    }
    
  </div>
}

function getRandomElement(arr) {
  // Get a random index from 0 to the length of the array - 1
  const randomIndex = Math.floor(Math.random() * arr.length);
  // Return the element at the random index
  return arr[randomIndex];
}


export default VideoGeneratorInput