import {useState, forwardRef, useImperativeHandle} from "react"
import { Button, Modal } from 'flowbite-react';
import {GoogleLoginPreset} from "../util.js"

const VideoGeneratorError = forwardRef(({setUser}, ref) => {
  const [generateErrorOpened, setGenerateErrorOpened] = useState(null)

  useImperativeHandle(ref, () => ({
    generateErrorOpened, setGenerateErrorOpened
  }))

  return <Modal show={generateErrorOpened} size="md" popup onClose={() => {setGenerateErrorOpened(false)}}>
    <Modal.Header>{/* {{"not-logged-in": "Login to continue", "out-of-balance": "Out of balance"}[generateErrorOpened]} */}</Modal.Header>
    {{
      "not-logged-in": <Modal.Body>
        <p className="text-white text-center mb-4">To use Etereel, sign in with google. It's one click, no credit card required.</p>
        <div className="w-full flex justify-center">
          <GoogleLoginPreset setUser={setUser} whiteTheme={true} onFinish={() => {
            setGenerateErrorOpened(false)
          }}/>
        </div>
      </Modal.Body>,
      "out-of-balance": <Modal.Body className="flex flex-col items-center">
        <p className="text-white text-center mb-4">You ran out of credits 😪</p>
        <Button pill gradientMonochrome="purple" as="a" href="/pricing">Get credits</Button>
      </Modal.Body>,
      "short-script": <Modal.Body className="flex flex-col items-center">
        <p className="text-white text-center mb-4">This script is too short 📏</p>
      </Modal.Body>
    }[generateErrorOpened]}
  </Modal>
})


export default VideoGeneratorError