import { Modal, Progress } from 'flowbite-react';
import { ft } from "../constants.js"
import { useState, useEffect } from "react"

export default function VideoGeneratorProgress({videoGeneratorInputRef, generatingVideoStage, generatingVideo}) {
  let task = "Generating"
  console.log(generatingVideoStage)

  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(videoGeneratorInputRef.current.generatingVideo)
  }, [videoGeneratorInputRef.current])

  if(videoGeneratorInputRef.current) {
    switch(videoGeneratorInputRef.current.generatingVideoStage) {
      case 0:
        task = videoGeneratorInputRef.current.startWithSelected == "script" ? "Processing script" : "Generating script"
        break
      case 1:
        task = "Generating voice-over"
        break
      case 2:
        task = "Generating subtitles"
        break
      case 3:
        task = "Generating images"
        break
      default:
        task = "skibidi"
        break
    }
  
  }

  console.log(videoGeneratorInputRef.current.generatingVideo)
  
  return <Modal show={show} size="md" popup>
    <Modal.Header />
    <Modal.Body>
      <div className="text-center flex flex-col items-center">
        <img src="loader.svg"></img>
        <Progress
          theme={ft}
          color="indigo"
          className="w-80 mb-2"
          progress={videoGeneratorInputRef.current.generatingVideoStage / 4 * 100}
          progressLabelPosition="inside"
          textLabel="Flowbite"
          textLabelPosition="outside"
          size="lg"
        />
        <p className="mb-5 text-lg font-normal text-white font-weight-900">{videoGeneratorInputRef.current.generatingVideoStage} / 4: {task}</p>
      </div>
    </Modal.Body>
  </Modal>
}