import {useState, useRef, forwardRef, useImperativeHandle} from "react"
import {SOUNDS, apiUrl} from "../constants.js"
import Editor from "../Editor.js"

const VideoEditorHandler = forwardRef(({show, script, videoGeneratorInputRef}, ref) => {
  const [rawTranscription, setRawTranscription] = useState(null)
  const [speech, setSpeech] = useState(null)
  const [videoDuration, setVideoDuration] = useState(5)
  const [slides, setSlides] = useState(null)
  const [subtitlesSettings, setSubtitlesSettings] = useState({
    fontSize: 100,
    align: "center",
    fontWeight: "900",
    fontFamily: "Inter",
    fontFamilyImportName: "Inter",
    animation: "bounce-in",
    animationLength: 200,
    fillColor: {
      r: 255,
      g: 255,
      b: 255
    },
    outlineSize: 15,
    outlineColor: {
      r: 0,
      g: 0,
      b: 0
    }
  })
  const [voiceoverSettings, setVoiceoverSettings] = useState({
    volume: 1
  })
  const [musicSettings, setMusicSettings] = useState({
    volume: 0.1,
    type: "tiktok-preselected",
    tiktokSoundSelected: SOUNDS[9],
    customSound: null
  })
  const [imageSettings, setImageSettings] = useState({
    animation: "zoom-out"
  })

  async function renderVideo() {
    let formData = new FormData();
    // rawTranscription, speech, videoDuration, slides, subtitlesSettings, voiceoverSettings, musicSettings, imageSettings
    formData.append("rawTranscription", JSON.stringify(rawTranscription))
    const rawSpeechBlob = await fetch(speech)
    const speechBlob = await rawSpeechBlob.blob()
    formData.append("files"/*"speech"*/, speechBlob, "speech.mp3")
    formData.append("videoDuration", videoDuration)
    formData.append("subtitlesSettings", JSON.stringify(subtitlesSettings))
    formData.append("voiceoverSettings", JSON.stringify(voiceoverSettings))
    formData.append("musicSettings", JSON.stringify({
      ...musicSettings,
      customSound: null
    }))
    formData.append("imageSettings", JSON.stringify(imageSettings))

    // slides
    const slidesFetchPromises = slides.map(async slide => {
      let f = await fetch(slide.imageType == "ai" ? slide.imageUrl : (slide.customImageUrl ? slide.customImageUrl : null))
      let b = await f.blob()
      return { id: slide.id, blob: b }
/*       return fetchFileContent(slide.imageUrl)
        .then(blob => ({ id: slide.id, blob: blob })); */
    });
    const slideBlobs = await Promise.all(slidesFetchPromises)
    slideBlobs.forEach(slideBlob => {
      if (slideBlob.blob) {
        // its gonna cause images not loading because not all images are .png
        console.log(slideBlob.blob)
        formData.append("files"/*'slide-' + slideBlob.id*/, slideBlob.blob, 'slide-' + slideBlob.id + '.png')/* 'slide-' + slideBlob.id + '.png' */
      }
    });
    formData.append("slides", JSON.stringify(slides))

    if(musicSettings.type == "custom" && musicSettings.customSound) {
      const rawMusicBlob = await fetch(musicSettings.customSound)
      const musicBlob = await rawMusicBlob.blob()
      formData.append("files"/*"custom-sound"*/, musicBlob, "music.mp3")
    }

    const request = await fetch(apiUrl + "api/render-reel", {
      method: "POST",
      body: formData,
      credentials: "include"
    })
    return request.blob()
  }

  useImperativeHandle(ref, () => ({
    setVideoDuration, setRawTranscription, setSlides,
    
    musicSettings, setMusicSettings,
    speech, setSpeech,
    subtitlesSettings, setSubtitlesSettings,
    imageSettings, setImageSettings
  }))

  return show
    ? <Editor renderVideo={renderVideo} {...{subtitlesSettings, setSubtitlesSettings, voiceoverSettings, setVoiceoverSettings, musicSettings, setMusicSettings, imageSettings, setImageSettings}} setSlides={setSlides} setVideoDuration={setVideoDuration} script={script} setRawTranscription={setRawTranscription} setSpeech={setSpeech} videoDuration={videoDuration} rawTranscription={rawTranscription} speech={speech} slides={slides}/>
    : null
})

export default VideoEditorHandler